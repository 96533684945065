<template>
  <div class="main">
    <div class="content-box">
      <div class="title">
        <!--            <el-select clearable v-model="delFlag" placeholder="站点状态" style="width: 120px;margin-left:20px;">-->
        <!--              <el-option label="正常" value="1"></el-option>-->
        <!--              <el-option label="停用" value="2"></el-option>-->
        <!--            </el-select>-->
        <el-input
          style="width:300px;"
          placeholder="搜索关键字"
          v-model="keyWord"
        >
          <el-button
            slot="suffix"
            @click="searchData"
            type="primary"
            icon="el-icon-search"
            style="border-radius:0 4px 4px 0;margin-right:-5px"
            >搜索</el-button
          >
        </el-input>

        <el-button
          @click="addHandler"
          type="primary"
          icon="el-icon-plus"
          style="margin-left:20px;"
          >新增</el-button
        >
      </div>
    </div>
    <div class="content-box">
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="80"
        ></el-table-column>
        <!--<el-table-column prop="plotCode" label="站点编号" align="center" min-width="70"></el-table-column>-->
        <el-table-column
          prop="plotName"
          label="站点名称"
          align="center"
          min-width="100"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="regionName"
          :formatter="regionName"
          label="所属区域"
          align="center"
          min-width="120"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="address"
          label="地址 "
          align="center"
          min-width="150"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="parkCarInfo"
          label="停车收费信息 "
          align="center"
          min-width="150"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="imageUrl"
          label="站点图片"
          align="center"
          min-width="60"
        >
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.fileUrl"
              style="height: 40px"
              :src="scope.row.fileUrl"
              :preview-src-list="[scope.row.fileUrl]"
            >
            </el-image>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="userId" label="代理商id " align="center" min-width="40"></el-table-column>-->
        <!-- <el-table-column prop="userName" label="代理商 " align="center" min-width="50"></el-table-column>-->
        <!--                <el-table-column prop="lng" label="纬度" align="center" min-width="60"></el-table-column>-->
        <!--                <el-table-column prop="lat" label="经度" align="center" min-width="60"></el-table-column>-->
        <el-table-column
          prop="createTime"
          label="创建时间 "
          align="center"
          min-width="120"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="160">
          <template slot-scope="scope">
            <div class="editCell">
              <el-button type="primary" @click="editCardHandler(scope.row)"
                >编辑</el-button
              >
              <el-button type="danger" @click="deteteHandlet(scope.row)"
                >删除</el-button
              >
              <!-- <el-link @click="editmenuHandler(scope.row)">配置岗位</el-link> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      :title="popupModalTitle"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <el-form
        ref="form"
        :model="formData"
        label-width="150px"
        :rules="formRule"
      >
        <div style="display: flex;">
          <el-form-item class="nav-form-item" label="站点名称" prop="plotName">
            <el-input
              maxlength="100"
              v-model="formData.plotName"
              style="width:550px;"
              placeholder="请输入站点名称"
              clearable
            />
          </el-form-item>
          <!--                <el-form-item class="nav-form-item" label="站点编号" prop="plotName">-->
          <!--                  <el-input maxlength="15" v-model="formData.plotCode" style="width:200px;" placeholder="请输入站点编号"/>-->
          <!--                </el-form-item>-->
        </div>
        <div style="display: flex;">
          <el-form-item
            class="nav-form-item nav-form-item-inner"
            label="位置信息"
          >
            <div style="display:flex;margin-bottom: -18px">
              <el-form-item
                prop="province"
                label-width="10"
                style="margin-right: 10px;"
              >
                <el-select
                  style="width:130px"
                  v-model="province"
                  clearable
                  filterable
                  @change="selProvince"
                  @clear="clearProvince"
                  placeholder="请选择省份"
                >
                  <el-option
                    v-for="item in provinceList"
                    :label="item.name"
                    :value="item.id"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                prop="city"
                label-width="10"
                style="margin-right: 10px;"
              >
                <el-select
                  @change="selCity"
                  style="width:130px"
                  v-model="city"
                  clearable
                  filterable
                  @clear="clearCity"
                  placeholder="请选择市"
                >
                  <el-option
                    v-for="item in cityList"
                    :label="item.name"
                    :value="item.id"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="regionCode" label-width="0">
                <el-select
                  @change="getXY"
                  style="width:140px"
                  clearable
                  filterable
                  v-model="formData.regionCode"
                  placeholder="请选择区"
                >
                  <el-option
                    v-for="item in areaList"
                    :label="item.name"
                    :value="item.id"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-button
                @click="show"
                type="primary"
                icon="el-icon-plus"
                style="margin-left:20px;"
                >选择位置</el-button
              >
            </div>
          </el-form-item>
        </div>
        <div>
          <el-form-item class="nav-form-item" label="详细地址" prop="lng">
            <el-input
              maxlength="30"
              v-model="formData.address"
              auto-complete="false"
              style="width:550px;"
              placeholder="请输入地址"
            />
          </el-form-item>
        </div>
        <el-form-item class="nav-form-item" label="建设场所" prop="lng">
          <el-select
            v-model="formData.constructionSite"
            placeholder="请选择"
            clearable
            filterable
            style="width: 550px"
          >
            <el-option
              v-for="item in CONSTRUCTION_SITE"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div>
          <el-form-item class="nav-form-item" label="所属运营商" prop="userId">
            <el-select
              v-model="formData.userId"
              style="width: 550px"
              placeholder="请选择运营商"
            >
              <el-option
                v-for="item in options"
                :label="item.operatorNm"
                :value="'' + item.operatorId"
                :key="item.operatorId"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <!--              <div style="display: flex;">-->
        <!--                <el-form-item class="nav-form-item" label="经度" prop="lng">-->
        <!--                  <el-input v-model="formData.lng" style="width:200px;" placeholder="请输入经度"/>-->
        <!--                </el-form-item>-->
        <!--                <el-form-item class="nav-form-item" label="纬度" prop="lat">-->
        <!--                  <el-input v-model="formData.lat" style="width:200px;" placeholder="请输入纬度"/>-->
        <!--                </el-form-item>-->
        <!--              </div>-->
        <el-form-item
          class="nav-form-item"
          label="停车收费信息"
          prop="parkCarInfo"
        >
          <el-input
            type="textarea"
            :rows="3"
            maxlength="120"
            v-model="formData.parkCarInfo"
            style="width:550px"
            placeholder="请输入停车收费信息"
          />
        </el-form-item>

        <el-form-item
          class="nav-form-item"
          label="停车收费类型"
          prop="parkCarStatus"
        >
          <el-radio-group v-model="formData.parkCarStatus">
            <el-radio :label="0">停车收费</el-radio>
            <el-radio :label="1">停车免费</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          class="nav-form-item"
          label="是否可以开发票"
          prop="receiptStatus"
        >
          <el-radio-group v-model="formData.receiptStatus">
            <el-radio :label="0">不可以</el-radio>
            <el-radio :label="1">可以</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          class="nav-form-item"
          label="配套设施"
          prop="supportingFacilitiesList"
        >
          <el-checkbox-group v-model="formData.supportingFacilitiesList">
            <el-checkbox :label="1">免费WIFI</el-checkbox>
            <el-checkbox :label="2">便利店</el-checkbox>
            <el-checkbox :label="3">洗车</el-checkbox>
            <el-checkbox :label="4">厕所</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item class="nav-form-item" label="上传站点图片" prop="fileId">
          <el-upload
            ref="upload"
            class="upload-demo"
            drag
            :action="uploadUrl"
            :headers="headers"
            :data="uploadData"
            :on-success="handleUploadSuccess"
            :show-file-list="false"
            multiple
          >
            <i class="el-icon-upload" style="width: 300px;"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传jpg/png文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item class="nav-form-item" label="站点图片" prop="title">
          <img v-if="imageUrl" :src="imageUrl" style="width: 300px;" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button :disabled="isDisable" type="primary" @click="confimHandler"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="选择位置"
      :visible.sync="isShowDialog"
      width="800px"
      @close="handleMapDialogClose"
    >
      <div class="search">
        <el-input
          placeholder="请输入搜索地址信息，例如：北京市故宫博物院"
          v-model="keyword"
          clearable
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchAddress"
          ></el-button>
        </el-input>
      </div>

      <div id="mapContainer" style="width: 100%; height: 300px"></div>

      <div class="address">
        <span>当前选点：</span>
        <b>{{ nowAddress }}</b>
        <el-button
          v-if="nowAddress"
          @click="selectAddress(nowAddress)"
          type="text"
          >【确认选择】</el-button
        >
      </div>

      <el-table
        highlight-current-row
        :data="nearPointList"
        height="250"
        style="width: 100%"
        class="table additem"
      >
        <el-table-column prop="address" label="附近推荐地点">
          <template slot-scope="scope">
            {{ scope.row.address }}{{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button @click="selectAddress(scope.row)" type="text"
              >确认选择</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="isShowDialog = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getPlotList,
  delPlot,
  addPlot,
  editPlot,
  getLngAndLat,
} from "@/api/plot";
import { getOperatorSelect } from "@/api/operator";

import { getProvinceList, getCityList, getPoltList } from "@/api/common";
import { getToken } from "@/utils/auth";
import { CONSTRUCTION_SITE } from "@/utils/const";
import { Result } from "element-ui";

export default {
  data() {
    return {
      CONSTRUCTION_SITE: CONSTRUCTION_SITE,
      isShowDialog: false,
      keyword: "", // 搜索关键词
      nearPointList: [], // 附近地址
      markersArray: [],
      geocoder: null,
      geoSearchService: null,
      nowAddress: null, // 选择的地点
      geocoderLocation: null,
      mainForm: {
        address: "",
        lat: "",
        lng: "",
      },
      uploadUrl: process.env.VUE_APP_BASE_API + "/file/uploadImg",
      headers: {
        token: getToken(),
      },
      uploadData: {
        business: "advert",
      },
      imageUrl: "",
      areaList: "",
      city: "",
      cityList: [],
      province: "",
      provinceList: [],
      keyWord: "",
      delFlag: "",
      isDisable: false,
      dataStatus: null,
      showStatus: null,
      loading: false,
      dialogVisible: false,
      operatorId: "",
      date: "",
      id: "",
      isAdd: true,
      search: "",
      tableData: [],
      page: 1,
      pageSize: 10,
      MAP: null,
      markerLayer: null,
      formData: {
        id: undefined,
        address: "",
        lat: "",
        lng: "",
        plotCode: "",
        plotName: "",
        regionCode: "",
        userId: "",
        fileId: "",
        parkCarInfo: "",
        parkCarStatus: 0,
        receiptStatus: 0,
        supportingFacilitiesList: [],
        constructionSite: "",
      },
      total: 0,

      options: [],
      formRule: {
        plotName: [
          { required: true, message: "请输入必填项", trigger: "blur" },
        ],
        userId: [{ required: true, message: "请选择", trigger: "blur" }],
        parkCarStatus: [
          { required: true, message: "请选择停车费类型", trigger: "blur" },
        ],
        receiptStatus: [
          { required: true, message: "请选择是否可以开发票", trigger: "blur" },
        ],
        supportingFacilitiesList: [
          // { required: true, message: "请选择配套设施", trigger: "blur" },
        ],
        // lng: [{required: true, message: '请输入经度', trigger: 'blur'}],
        // lat: [{required: true, message: '请输入纬度', trigger: 'blur'}],
        regionCode: [{ required: true, message: "请选择区", trigger: "blur" }],
        fileId: [
          { required: true, message: "请上传站点图片", trigger: "blur" },
        ],
      },
      popupModalTitle: "新增",
    };
  },
  created() {
    this.getDataList();
    this.getOperatorSelect();
    this.getProvinceList();
  },
  methods: {
    // 搜索地址
    searchAddress() {
      if (!this.keyword) {
        return this.$message.error("请输入搜索地址信息");
      }
      this.setLocationByAddress(this.keyword);
    },

    // 初始化地图
    initMap() {
      //let latLon = new qq.maps.LatLng(39.916527, 116.397128);
      this.MAP = new TMap.Map("mapContainer", {
        zoom: 13,
        center: new TMap.LatLng(39.916527, 116.397128),
        // mapTypeId: qq.maps.MapTypeId.ROADMAP,
      });
      this.markerLayer = new TMap.MultiMarker({
        id: "marker-layer",
        map: this.MAP,
      });
      this.MAP.on("click", (event) => {
        this.setLocationByLatLng(event.latLng);
      });
      // 经纬度解析类回调函数
      this.geocoder = new TMap.service.Geocoder();
      this.geoSearchService = new TMap.service.Search({ pageSize: 10 });
      // this.geocoder = new qq.maps.Geocoder({
      //   complete: function(result) {
      //     console.log("🚀 ~ file: plotList.vue:553 ~ result:", result);
      //     console.log(result.detail);
      //     that.nowAddress = result.detail;
      //     that.nearPointList = result.detail.nearPois;
      //     map.setCenter(result.detail.location);
      //     // 标记点
      //     let marker = new qq.maps.Marker({
      //       map: map,
      //       position: result.detail.location,
      //     });
      //     that.markersArray.push(marker);
      //     if (that.markersArray.length > 1) {
      //       for (let i = 0; i < that.markersArray.length - 1; i++) {
      //         that.markersArray[i].setMap(null); // 清除标记
      //       }
      //     }
      //   },
      // });
      // this.geoSearchService = new qq.maps.SearchService({
      //   complete: function(results) {
      //     var pois = results.detail.pois;
      //     that.nearPointList = results.detail.pois;
      //     var latlngBounds = new qq.maps.LatLngBounds();
      //     for (var i = 0, l = pois.length; i < l; i++) {
      //       var poi = pois[i];
      //       latlngBounds.extend(poi.latLng);
      //     }
      //     map.fitBounds(latlngBounds);
      //   },
      // });

      // // 地址解析回调函数
      // this.geocoderLocation = new qq.maps.Geocoder({
      //   complete: function(result) {
      //     // 查找附近的点
      //     let latLng = new qq.maps.LatLng(
      //       result.detail.location.lat,
      //       result.detail.location.lng
      //     );
      //     that.geocoder.getAddress(latLng);
      //   },
      // });
    },
    handleMapDialogClose() {
      if (this.MAP) {
        this.MAP.destroy();
      }
      this.nearPointList = [];
    },
    // 选择地址事件
    selectAddress(item) {
      this.isDisable = false;

      console.log(item);

      this.formData.lng = item.location.lng;
      this.formData.lat = item.location.lat;
      this.formData.address = item.address.replace("中国", "");

      var ssq = [
        item.ad_info.province || item.ad_info.city,
        item.ad_info.city,
        item.ad_info.district,
      ];
      this.selectSSQ(ssq);
      this.isShowDialog = false;
    },
    selectSSQ(ssq) {
      this.provinceList.map((item1) => {
        if (item1.name.indexOf(ssq[0]) != -1) {
          console.log(item1.name + "--" + ssq[0]);
          this.province = item1.id;
          getCityList({ pid: item1.id }).then((res2) => {
            this.cityList = [...res2.data];
            this.cityList.map((item2) => {
              if (item2.name.indexOf(ssq[1]) != -1) {
                this.city = item2.id;
                getCityList({ pid: item2.id }).then((res3) => {
                  this.areaList = [...res3.data];
                  this.areaList.map((item3) => {
                    if (item3.name.indexOf(ssq[2]) != -1) {
                      this.formData.regionCode = item3.id;
                    }
                  });
                });
              }
            });
          });
        }
      });
      this.formData.address = this.formData.address
        .replace(ssq[0], "")
        .replace(ssq[1], "")
        .replace(ssq[2], "");
    },

    // 显示地图
    show() {
      this.isShowDialog = true;
      setTimeout(() => {
        var sdd = "";
        if (this.province) {
          if (isNaN(this.province)) {
            sdd = sdd + this.province;
          } else {
            this.provinceList.map((item) => {
              if (item.id == this.province) {
                sdd = sdd + item.name;
              }
            });
          }
        }
        if (this.city) {
          if (isNaN(this.city)) {
            sdd = sdd + this.city;
          } else {
            this.cityList.map((item) => {
              if (item.id == this.city) {
                sdd = sdd + item.name;
              }
            });
          }
        }
        if (this.formData.regionCode) {
          this.areaList.map((item) => {
            if (item.id == this.formData.regionCode) {
              sdd = sdd + item.name;
            }
          });
        }

        this.keyword = sdd + this.formData.address;
        this.initMap();
        if (this.keyword) {
          this.searchAddress();
        }
      });
    },

    // 根据地址信息进行定位
    setLocationByAddress(address) {
      console.log(
        "🚀 ~ file: plotList.vue:674 ~ address:",
        this.geocoderLocation
      );

      this.geoSearchService
        .searchRegion({
          keyword: address,
          cityName: "中国",
        })
        .then((result) => {
          this.nearPointList = result.data;

          if (result.data.length > 0) {
            this.markerLayer.setGeometries([
              {
                position: result.data[0].location,
              },
            ]);
            this.MAP.setCenter(result.data[0].location);
          }
        });
    },

    // 根据经纬度进行定位
    setLocationByLatLng(latLng) {
      setTimeout(() => {
        this.geocoder
          .getAddress({ location: latLng, getPoi: true })
          .then((res) => {
            this.markerLayer.setGeometries([
              {
                position: latLng,
              },
            ]);
            this.nearPointList = res.result.pois;
            console.log(res);
            this.nowAddress = res.result.address;
          });
      });
    },

    getXY() {
      if (this.formData.regionCode && this.formData.address) {
        let address = [this.formData.address];
        this.areaList.map((item) => {
          if (item.id == this.formData.regionCode) {
            address.push(item.name);
            this.cityList.map((item1) => {
              if (item1.id == item.pid) {
                address.push(item1.name);
                this.provinceList.map((item2) => {
                  if (item2.id == item1.pid) {
                    address.push(item2.name);
                  }
                });
              }
            });
          }
        });
        address = address.reverse();
        getLngAndLat({
          address: address
            .join("")
            .split(" ")
            .join(""),
        }).then((res) => {
          if (res.data) {
            this.formData.lat = res.data.lat;
            this.formData.lng = res.data.lng;
          }
        });
      }
    },
    handleUploadSuccess(res, file) {
      this.formData.fileId = res.data.imgID;
      this.imageUrl = URL.createObjectURL(file.raw);
      this.$refs.upload.clearFiles();
    },
    regionName(row) {
      return row.province + row.city + row.regionName;
    },
    remoteMethod(query) {
      if (query !== "") {
        console.log(query);
        this.loading = true;
        // this.options = this.list.filter(item => {
        //   return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
        // })
      } else {
        this.options = [];
      }
    },
    selProvince(val) {
      let param = {
        pid: val,
      };
      if (val) {
        getCityList(param).then((res) => {
          this.cityList = [...res.data];
          this.city = "";
          this.formData.regionCode = "";
        });
      } else {
        this.cityList = [];
        this.city = "";
        this.formData.regionCode = "";
      }
    },
    clearProvince() {
      this.city = "";
      this.formData.regionCode = "";
    },
    getProvinceList() {
      getProvinceList().then((res) => {
        this.provinceList = [...res.data];
      });
    },
    selCity(val) {
      if (val) {
        let param = {
          pid: val,
        };
        getCityList(param).then((res) => {
          this.areaList = [...res.data];
          this.formData.regionCode = "";
        });
      } else {
        this.areaList = "";
        this.formData.regionCode = "";
      }
    },
    clearCity() {
      this.formData.regionCode = "";
    },
    getOperatorSelect() {
      getOperatorSelect().then((res) => {
        if (res.data && res.data.length > 0) {
          this.options = [...res.data];
          this.operatorId = res.data[0].operatorId;
        }
      });
    },
    searchData() {
      this.page = 1;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      let param = {
        keyWord: this.keyWord,
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      getPlotList(param).then((res) => {
        if (res.data && res.data.records.length >= 0) {
          this.tableData = [...res.data.records];
          this.total = res.data.total;
        }

        this.loading = false;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getDataList();
    },
    addHandler() {
      this.isAdd = true;
      this.formData.address = "";
      this.formData.plotCode = "";
      this.formData.plotName = "";
      this.formData.regionCode = "";
      this.formData.userId = "";
      this.formData.fileId = "";
      this.popupModalTitle = "新增";
      this.province = "";
      this.city = "";
      this.formData.regionCode = "";
      this.formData.lat = "";
      this.formData.lng = "";
      this.formData.parkCarInfo = "";
      this.formData.supportingFacilitiesList = [];
      this.formData.constructionSite = "";
      this.imageUrl = "";
      if (this.formData.id) {
        delete this.formData.id;
      }
      this.dialogVisible = true;
    },
    confimHandler() {
      if (!this.formData.lng || !this.formData.lat) {
        this.$message({
          message: "请选择位置",
          type: "error",
        });
        return false;
      }

      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.addCard();
          } else {
            this.editCard();
          }
        }
      });
    },
    deleteHandler(row) {
      this.$confirm("确定删除所选项吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          id: row.id,
        };
        delPromotion(param).then((res) => {
          if (res.message.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.getDataList();
          } else {
            this.$message.error(res.message.message);
          }
        });
      });
    },
    initSelect(province, city, region) {
      var that = this;
      let param = {
        pid: province,
      };
      getCityList(param).then((res) => {
        this.cityList = [...res.data];
        let param = {
          pid: city,
        };
        getCityList(param).then((res) => {
          this.areaList = [...res.data];
        });
      });
    },
    editCardHandler(row) {
      this.initSelect(row.provinceCode, row.cityCode, row.regionCode);
      this.isAdd = false;
      this.formData.id = row.id;
      this.formData.address = row.address;
      this.formData.parkCarStatus = Number(row.parkCarStatus);
      this.formData.receiptStatus = Number(row.receiptStatus);
      this.formData.supportingFacilitiesList =
        (row.supportingFacilities &&
          row.supportingFacilities.split(",").map((item) => Number(item))) ||
        [];
      this.formData.lat = row.lat;
      this.formData.lng = row.lng;
      this.formData.plotCode = row.plotCode;
      this.formData.plotName = row.plotName;
      this.formData.fileId = row.fileId || row.fileUrl;
      this.formData.regionCode = row.regionCode;
      this.formData.userId = row.userId;
      this.popupModalTitle = "编辑";
      this.province = row.province;
      this.city = row.city;
      this.formData.regionCode = row.regionCode;
      this.formData.parkCarInfo = row.parkCarInfo;
      this.formData.constructionSite = row.constructionSite;
      this.dialogVisible = true;
      this.imageUrl = row.fileUrl;
    },
    editCard() {
      this.isDisable = true;
      if (this.imageUrl == this.formData.fileId) {
        this.formData.fileId = null;
      }
      if (!this.formData.constructionSite) {
        this.formData.constructionSite = null;
      }
      editPlot(this.formData).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.isDisable = false;
          this.dialogVisible = false;
          this.getDataList();
        } else {
          this.$message.error(res.data);
        }
      });
    },
    addCard() {
      this.isDisable = true;
      addPlot(this.formData).then((res) => {
        if (res.message.code == -1) {
          this.$message.error(res.message.message);
        } else {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.isDisable = false;
          this.dialogVisible = false;
          this.getDataList();
        }
      });
    },
    deteteHandlet(row) {
      if (row.pileNum && row.pileNum > 0) {
        this.$message.error("此站点下有运营的电桩，不可删除");
        return;
      }
      this.$confirm("确定删除所选项吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let para = {
          id: row.id,
        };
        delPlot(para).then((res) => {
          if (res.message.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.isDisable = false;
            this.dialogVisible = false;
            this.getDataList();
          } else {
            this.$message.error(res.data);
          }
        });
      });
    },
  },
};
</script>
<style scoped>
.additem >>> td {
  padding: 0;
}

.title {
  display: flex;
  flex-direction: row;
}

.el-icon-delete {
  color: #e8f3fe;
}
</style>
