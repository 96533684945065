var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("div", { staticClass: "content-box" }, [
        _c(
          "div",
          { staticClass: "title" },
          [
            _c(
              "el-form",
              {
                ref: "serachForm",
                attrs: {
                  model: _vm.searchForm,
                  "label-suffix": ":",
                  "label-width": "120px",
                },
              },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { "flex-wrap": "wrap" },
                    attrs: { gutter: 20, type: "flex" },
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "电表编号" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.searchForm.ammeterNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "ammeterNo", $$v)
                                },
                                expression: "searchForm.ammeterNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "电表名称" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.searchForm.ammeterName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "ammeterName", $$v)
                                },
                                expression: "searchForm.ammeterName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "通讯状态" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { placeholder: "请选择", clearable: "" },
                                on: { change: _vm.handleSelectOperator },
                                model: {
                                  value: _vm.searchForm.stat,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "stat", $$v)
                                  },
                                  expression: "searchForm.stat",
                                },
                              },
                              _vm._l(_vm.messageStat, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticStyle: { "flex-wrap": "wrap" },
                    attrs: { gutter: 20, type: "flex" },
                  },
                  [
                    _vm.isOperator != 1
                      ? _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "运营商名称" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.realName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "realName",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.realName",
                                    },
                                  },
                                  _vm._l(
                                    _vm.operatorList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.realName,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "站点名称" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  multiple: "",
                                  placeholder: "请选择",
                                  clearable: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleSelectState(
                                      _vm.searchForm.plotId
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.searchForm.plotId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "plotId", $$v)
                                  },
                                  expression: "searchForm.plotId",
                                },
                              },
                              _vm._l(_vm.plotList, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.plotName,
                                    value: item.id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: _vm.isOperator == 1 ? 16 : 8 } },
                      [
                        _c(
                          "div",
                          { staticStyle: { float: "right" } },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "20px" },
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.searchData },
                              },
                              [_vm._v("查询")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "20px" },
                                attrs: { plain: "", icon: "el-icon-refresh" },
                                on: { click: _vm.resetData },
                              },
                              [_vm._v("重置")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content-box" },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "align-items": "center",
                "margin-bottom": "12px",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { overflow: "hidden" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isOperator != 1,
                          expression: "isOperator != 1",
                        },
                      ],
                      attrs: { type: "primary", icon: "el-icon-plus" },
                      on: { click: _vm.handleAddPowerData },
                    },
                    [_vm._v("新增")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.offline != 0
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "right",
                        color: "#6b5757",
                        "font-size": "14px",
                      },
                    },
                    [
                      _vm._v("共 "),
                      _c(
                        "span",
                        { staticStyle: { color: "rgba(223, 55, 55, 1)" } },
                        [_vm._v(_vm._s(_vm.offline) + " ")]
                      ),
                      _vm._v("个离线"),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tableData },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  align: "center",
                  width: "60",
                  fixed: "",
                },
              }),
              _vm._v(" "),
              _vm.isOperator != 1
                ? _c("el-table-column", {
                    attrs: {
                      prop: "userName",
                      label: "运营商名称",
                      align: "center",
                      "min-width": "130",
                      fixed: "",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "ammeterNo",
                  label: "电表编号",
                  align: "center",
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                  fixed: "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "ammeterName",
                  label: "电表名称",
                  align: "center",
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                  fixed: "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "ammeterBrand",
                  label: "电表品牌",
                  align: "center",
                  "min-width": "130",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.ammeterBrand != ""
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  overflow: "hidden",
                                  "white-space": "nowrap",
                                  "text-overflow": "ellipsis",
                                },
                              },
                              [_vm._v(_vm._s(scope.row.ammeterBrand))]
                            )
                          : _c("div", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "ammeterModel",
                  label: "电表型号",
                  align: "center",
                  "min-width": "130",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.ammeterModel != ""
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  overflow: "hidden",
                                  "white-space": "nowrap",
                                  "text-overflow": "ellipsis",
                                },
                              },
                              [_vm._v(_vm._s(scope.row.ammeterModel))]
                            )
                          : _c("div", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "stat",
                  align: "center",
                  label: "通讯状态",
                  "min-width": "130",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "center",
                            },
                          },
                          [
                            row.stat == 0
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "50px",
                                      height: "30px",
                                      padding: "4px 4px",
                                      "border-radius": "4px",
                                      color: "#DF3737",
                                      background: "rgba(223, 55, 55, 0.06)",
                                    },
                                  },
                                  [_vm._v("\n              离线")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            row.stat == 1
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "50px",
                                      height: "30px",
                                      padding: "4px 4px",
                                      "border-radius": "4px",
                                      color: "#DF3737",
                                      background: "rgba(223, 55, 55, 0.06)",
                                    },
                                  },
                                  [_vm._v("\n              故障")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            row.stat == 2
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "50px",
                                      height: "30px",
                                      padding: "4px 4px",
                                      "border-radius": "4px",
                                      color: "#057C98",
                                      background: "rgba(223, 55, 55, 0.06)",
                                    },
                                  },
                                  [_vm._v("\n              在线")]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "wireMethod",
                  label: "接线方式",
                  align: "center",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.wireMethod == 1
                          ? _c("div", [_vm._v("单相单线")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.wireMethod == 2
                          ? _c("div", [_vm._v("三相三线")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.wireMethod == 3
                          ? _c("div", [_vm._v("三相四线")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "plotName",
                  label: "站点名称",
                  align: "center",
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "installPosition",
                  label: "安装位置",
                  align: "center",
                  "min-width": "130",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.installPosition != ""
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  overflow: "hidden",
                                  "white-space": "nowrap",
                                  "text-overflow": "ellipsis",
                                },
                              },
                              [_vm._v(_vm._s(scope.row.installPosition))]
                            )
                          : _c("div", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "positionPics",
                  label: "安装位置图片",
                  align: "center",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.positionPics[0] != ""
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#057C98",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlePreviewImages(scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看图片")]
                            )
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#057C98",
                                },
                              },
                              [_vm._v("-")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建时间",
                  align: "center",
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "center",
                  width: _vm.isOperator == 1 ? 0 : 180,
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.isOperator != 1
                          ? _c(
                              "div",
                              { staticClass: "editCell" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerEditAction(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerDelItem(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.page,
              "page-size": _vm.listPageSize,
              layout: "total, prev, pager, next,sizes, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            id: "popupMoal",
            title: _vm.addDialogTitle,
            visible: _vm.dialogVisibleAdd,
            width: "752px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleAdd = $event
            },
            close: _vm.handleDialogAddClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formDataAddRef",
              attrs: {
                model: _vm.formDataAdd,
                "label-position": "left",
                rules: _vm.formDataAddRule,
              },
            },
            [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "form-item-content" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "nav-form-item",
                        attrs: { prop: "ammeterName" },
                      },
                      [
                        _c("div", [
                          _c("span", { staticStyle: { color: "#DF3737" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(" 电表名称"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-input", {
                              staticStyle: { width: "340px" },
                              attrs: {
                                maxlength: "30",
                                placeholder: "请输入",
                                "show-word-limit": "",
                                clearable: "",
                              },
                              model: {
                                value: _vm.formDataAdd.ammeterName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formDataAdd, "ammeterName", $$v)
                                },
                                expression: "formDataAdd.ammeterName",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "nav-form-item",
                        attrs: { label: "", prop: "ammeterNo" },
                      },
                      [
                        _c("div", [
                          _c("span", { staticStyle: { color: "#DF3737" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(" 电表编号"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-input", {
                              staticStyle: { width: "340px" },
                              attrs: {
                                maxlength: "30",
                                placeholder: "填写电表实际序列号(电表唯一编码)",
                                "show-word-limit": "",
                                clearable: "",
                              },
                              model: {
                                value: _vm.formDataAdd.ammeterNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formDataAdd, "ammeterNo", $$v)
                                },
                                expression: "formDataAdd.ammeterNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "form-item-content" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "nav-form-item",
                          attrs: { label: "电表品牌" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "340px" },
                            attrs: {
                              maxlength: 30,
                              placeholder: "请输入",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.formDataAdd.ammeterBrand,
                              callback: function ($$v) {
                                _vm.$set(_vm.formDataAdd, "ammeterBrand", $$v)
                              },
                              expression: "formDataAdd.ammeterBrand",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "NumberType nav-form-item",
                          attrs: { label: "电表型号" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "340px" },
                            attrs: {
                              maxlength: 30,
                              placeholder: "请输入",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.formDataAdd.ammeterModel,
                              callback: function ($$v) {
                                _vm.$set(_vm.formDataAdd, "ammeterModel", $$v)
                              },
                              expression: "formDataAdd.ammeterModel",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-item-content" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "NumberType nav-form-item",
                          attrs: { label: "接线方式", prop: "wireMethod" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "340px" },
                              attrs: {
                                placeholder: "请选择",
                                disabled:
                                  _vm.addDialogTitle[0] == "编" ? true : false,
                              },
                              model: {
                                value: _vm.formDataAdd.wireMethod,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formDataAdd, "wireMethod", $$v)
                                },
                                expression: "formDataAdd.wireMethod",
                              },
                            },
                            _vm._l(
                              _vm.wireMethodOption,
                              function (item_w, index_w) {
                                return _c("el-option", {
                                  key: index_w,
                                  attrs: {
                                    label: item_w.label,
                                    value: item_w.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "NumberType nav-form-item",
                          attrs: { label: " 站点名称", prop: "plotId" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "340px" },
                              attrs: { placeholder: "关联站点" },
                              on: { change: _vm.handleSelPlotVal },
                              model: {
                                value: _vm.formDataAdd.plotId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formDataAdd, "plotId", $$v)
                                },
                                expression: "formDataAdd.plotId",
                              },
                            },
                            _vm._l(_vm.addSelPlotList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.plotName, value: item.id },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _vm.isInsertEdit == 1 &&
                          _vm.showEditTipText &&
                          _vm.addDialogTitle[0] == "编"
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#F56C6C",
                                    "font-size": "12px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                电表已加入有序充电-" +
                                      _vm._s(_vm.delItamPowerlineName) +
                                      "中应用，变更站点后，线路层级中的电表及其下的支点将一并从" +
                                      _vm._s(_vm.delItamPowerlineName) +
                                      "中移除"
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-item-content" },
                    [
                      _c(
                        "el-form-item",
                        {
                          key: "3",
                          staticClass: "nav-form-item",
                          attrs: { label: "安装位置" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "340px" },
                            attrs: {
                              type: "textarea",
                              maxlength: 150,
                              placeholder: "请输入",
                              rows: "7",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.formDataAdd.installPosition,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formDataAdd,
                                  "installPosition",
                                  $$v
                                )
                              },
                              expression: "formDataAdd.installPosition",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "nav-form-item",
                          staticStyle: { width: "340px" },
                          attrs: { label: "", prop: "fileId" },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n                安装位置图片\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    limit: 9,
                                    action: _vm.uploadUrl,
                                    headers: _vm.headers,
                                    data: _vm.uploadData,
                                    "list-type": "picture-card",
                                    "on-change": _vm.handleStartChange,
                                    "on-success": _vm.handleFileSuccess,
                                    "on-exceed": _vm.handleImgExceed,
                                    "file-list": _vm.showImgList,
                                    multiple: "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "file",
                                      fn: function ({ file }) {
                                        return _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "100%",
                                              height: "100%",
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass:
                                                "el-upload-list__item-thumbnail",
                                              attrs: {
                                                src: file.response.data.imgUrl,
                                                alt: "",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "el-upload-list__item-actions",
                                              },
                                              [
                                                !_vm.disabledImg
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "el-upload-list__item-delete",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleRemove(
                                                              file
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-delete",
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        )
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-plus",
                                    attrs: { slot: "default" },
                                    slot: "default",
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  staticStyle: {
                                    "margin-top": "0",
                                    "font-size": "10px",
                                    color: "#86909C",
                                  },
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _vm._v(
                                    "\n                    图片用于定位电表安装位置；尺寸不限，jpg/png格式，最多九张\n                  "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div"),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisibleAdd = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  key: "save",
                  attrs: { type: "primary", disabled: _vm.isDisabled },
                  on: { click: _vm.confimHandler },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            id: "popupLogMoal",
            title: "电表数据",
            visible: _vm.dialogVisibleLog,
            width: "650px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleLog = $event
            },
            close: _vm.handleDialogClose,
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.plotIdTableData },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  align: "center",
                  width: "60",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "userName",
                  label: "时间",
                  align: "center",
                  "min-width": "130",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "ammeterNo",
                  label: "总（度）",
                  align: "center",
                  "min-width": "100",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "ammeterName",
                  label: "A相电流（A）",
                  align: "center",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "ammeterBrand",
                  label: "C相电流（C）",
                  align: "center",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "ammeterModel",
                  label: "剩余电流（A）",
                  align: "center",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "stat",
                  align: "center",
                  label: "总功率（kW）",
                  "min-width": "130",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.stat == 0 ? _c("div", [_vm._v("离线")]) : _vm._e(),
                        _vm._v(" "),
                        row.stat == 1 ? _c("div", [_vm._v("故障")]) : _vm._e(),
                        _vm._v(" "),
                        row.stat == 2 ? _c("div", [_vm._v("在线")]) : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "plotName",
                  label: "A相功率（kW）",
                  align: "center",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "installPosition",
                  label: "B相功率（kW）",
                  align: "center",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "positionPics",
                  label: "C相功率（kW）",
                  align: "center",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "A相电压（V）",
                  align: "center",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "B相电压（V）",
                  align: "center",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "C相电压（V）",
                  align: "center",
                  "min-width": "100",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisibleImage, title: "安装位置图片预览" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleImage = $event
            },
          },
        },
        [
          _c(
            "el-carousel",
            {
              staticClass: "image-swiper",
              attrs: {
                height: "100%",
                "indicator-position":
                  _vm.currentImages.length <= 1 ? "none" : "",
                arrow: _vm.currentImages.length <= 1 ? "never" : "",
                autoplay: false,
              },
            },
            _vm._l(_vm.currentImages, function (image, $i) {
              return _c(
                "el-carousel-item",
                { key: $i },
                [
                  _c("el-image", {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: {
                      src: image,
                      "preview-src-list": _vm.currentImages.map((v) => v),
                      "initial-index": $i,
                      fit: "cover",
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisibleImage = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "确定删除电表：" + _vm.delItamPowerName,
            visible: _vm.dialogVisibleDel,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleDel = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "20px", "font-size": "16px" } },
            [_vm._v("此操作不可逆，请确认电表未使用后再谨慎操作")]
          ),
          _vm._v(" "),
          _vm.isInsert == 1
            ? _c(
                "div",
                {
                  staticStyle: {
                    "line-height": "20px",
                    "font-weight": "bold",
                    "font-size": "14px",
                    color: "#F56C6C",
                  },
                },
                [
                  _vm._v(
                    "\n      电表已加入 有序充电-" +
                      _vm._s(_vm.delItamPowerlineName) +
                      " 中应用，删除电表后，线路层级中的电表及其下的支点将一并从 " +
                      _vm._s(_vm.delItamPowerlineName) +
                      " 移除"
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisibleDel = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSureDel },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }