<template>
  <div class="main">
    <div class="content-box">
      <div class="title">
        <el-button
          @click="addHandler"
          type="primary"
          style="margin-left:20px;"
          icon="el-icon-plus"
          >新增</el-button
        >
      </div>
    </div>
    <div class="content-box">
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="80"
          fixed="left"
        ></el-table-column>
        <el-table-column
          prop="pileCode"
          align="center"
          label="电桩编号"
          min-width="140"
          fixed="left"
        ></el-table-column>
        <el-table-column
          prop="pileName"
          align="center"
          label="电桩名称"
          min-width="120"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="pileType"
          align="center"
          label="电桩类型"
          :formatter="cpPileType"
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="name"
          align="center"
          label="电枪名称"
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="connectorNum"
          align="center"
          label="电枪编号"
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="connectorCode"
          align="center"
          label="电枪编码"
          min-width="160"
        ></el-table-column>
        <el-table-column
          prop="stat"
          align="center"
          :formatter="forState"
          label="状态"
          min-width="80"
        ></el-table-column>

        <!--                <el-table-column prop="pileType" align="center" label="设备类型" min-width="80"></el-table-column>-->
        <el-table-column
          prop="createtime"
          label="创建时间"
          align="center"
          min-width="160"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" align="center" width="320" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" @click="showQrcode(scope.row)"
              >二维码</el-button
            >
            <el-button type="primary" @click="editCardHandler(scope.row)"
              >编辑</el-button
            >
            <!-- <el-link @click="getUseRecodsHandler(scope.row)">使用记录</el-link> -->
            <el-button type="danger" @click="deleteHandler(scope.row)"
              >删除</el-button
            >
            <el-button
              type="success"
              @click="switchPort(scope.row.id, 1)"
              v-show="scope.row.state != 'N' && scope.row.state != 'Y'"
              >开启</el-button
            >
            <el-button
              @click="switchPort(scope.row.id, 0)"
              v-show="scope.row.state != 'F'"
              >关闭</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      :title="popupModalTitle"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <el-form
        ref="form"
        :model="formData"
        label-width="100px"
        :rules="formRule"
      >
        <el-form-item
          class="nav-form-item"
          label="电枪编号"
          prop="connectorNum"
        >
          <el-input
            maxlength="14"
            :disabled="!isAdd"
            v-model="formData.connectorNum"
            @change="handleConnectorNumChange"
            style="width:300px;"
            placeholder="请输入电枪编号"
            clearable
          />
        </el-form-item>
        <template v-if="!isAdd">
          <el-form-item
            class="nav-form-item"
            label="电枪编码"
            prop="connectorCode"
          >
            <el-input
              maxlength="14"
              :disabled="!isAdd"
              v-model="formData.connectorCode"
              style="width:300px;"
              clearable
            />
          </el-form-item>
        </template>

        <el-form-item class="nav-form-item" label="电枪名称" prop="name">
          <el-input
            maxlength="20"
            v-model="formData.name"
            style="width:300px;"
            placeholder="请输入电枪名称"
            clearable
          />
        </el-form-item>
        <el-form-item class="nav-form-item" label="电枪状态" prop="state">
          <el-radio v-model="formData.state" label="Y">占用</el-radio>
          <el-radio v-model="formData.state" label="N">未占用</el-radio>
          <el-radio v-model="formData.state" label="F">故障</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" :loading="isDisable" @click="confimHandler"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="二维码" :visible.sync="codeDialogVisible" width="500px">
      <div style="display: flex; flex-direction: column;align-items: center;">
        <canvas
          ref="qrcodeContainer"
          style="width: 200px; height: 200px;"
        ></canvas>
        <el-button
          type="text"
          style="margin-bottom: 16px;"
          @click="hanldeCopyCodeUrl"
          >{{ currentCodeUrl }}</el-button
        >
        <el-button type="primary" @click="downloadQRCode">下载</el-button>
      </div>
      <div slot="footer">
        <el-button @click="codeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="codeDialogVisible = false"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getPortList, delPort, addPort, editPort } from '@/api/port';
import { switchPort } from '../../api/port';
import QRCode from 'qrcode';
import { PORT_STATUS, findLabelByValue } from '@/utils/const';

export default {
  data() {
    return {
      isDisable: false,
      dialogVisible: false,
      date: '',
      isAdd: true,
      search: '',
      tableData: [],
      page: 1,
      pageSize: 10,
      loading: true,
      formData: {
        name: '',
        pileType: '',
        pileId: this.pileId,
        connectorNum: '',
        connectorCode: '',
        state: '',
      },
      total: 0,

      formRule: {
        connectorNum: [
          { required: true, message: '请输入端口编号', trigger: 'blur' },
        ],
        name: [{ required: true, message: '请输入端口名称', trigger: 'blur' }],
        state: [{ required: true, message: '请选择端口状态', trigger: 'blur' }],
      },
      popupModalTitle: '新增',
      codeDialogVisible: false,
      currentCodeUrl: '',
    };
  },
  created() {
    this.getCardList();
  },
  computed: {
    pileId() {
      return this.$route.query.pileId;
    },
  },
  methods: {
    cpPileType(row) {
      if (row.pileType == 1) {
        return '直流';
      } else if (row.pileType == 0) {
        return '交流';
      }
    },
    switchPort(id, type) {
      let params = {
        id: id,
        type: type,
      };
      switchPort(params).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: (type == 1 ? '端口开启' : '端口关闭') + '成功',
            type: 'success',
          });
          this.getCardList();
        } else {
          this.$message({
            message: res.message.message,
            type: 'success',
          });
        }
      });
    },
    forState(row) {
      return findLabelByValue(PORT_STATUS, row.stat);
    },
    getCardList() {
      this.loading = true;
      let param = {
        pileId: this.pileId,
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      getPortList(param).then((res) => {
        if (res.data) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }

        this.loading = false;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getCardList();
    },
    addHandler() {
      this.isAdd = true;
      this.formData.pileType = '';
      this.formData.connectorNum = '';
      this.formData.name = '';
      this.formData.pileId = this.pileId;
      this.formData.state = '';
      this.popupModalTitle = '新增';
      this.dialogVisible = true;
    },
    confimHandler() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.add();
          } else {
            this.edit();
          }
        }
      });
    },
    deleteHandler(row) {
      this.$confirm('确定删除所选项吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let param = {
          id: row.id,
        };
        delPort(param).then((res) => {
          if (res.message.code == 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
            });
            this.getCardList();
          } else {
            this.$message.error(res.message.message);
          }
        });
      });
    },
    editCardHandler(row) {
      this.isAdd = false;
      this.formData.pileType = this.pileType + '';
      this.formData.connectorNum = row.connectorNum;
      this.formData.connectorCode = row.connectorCode;
      this.formData.name = row.name;
      this.formData.pileCode = row.pileCode;
      this.formData.state = row.state;
      this.formData.id = row.id;
      this.popupModalTitle = '编辑';
      this.dialogVisible = true;
    },
    edit() {
      this.isDisable = true;
      editPort(this.formData)
        .then((res) => {
          if (res.message.code == -1) {
            this.$message.error(res.data);
          } else {
            this.$message({
              message: '操作成功',
              type: 'success',
            });
            this.isDisable = false;
            this.dialogVisible = false;
            this.getCardList();
          }
        })
        .catch((e) => {
          this.$message.error(e);
          this.isDisable = false;
        });
    },
    add() {
      this.isDisable = true;
      addPort(this.formData)
        .then((res) => {
          if (res.message.code == -1) {
            this.$message.error(res.data);
          } else {
            this.$message({
              message: '操作成功',
              type: 'success',
            });
            this.isDisable = false;
            this.dialogVisible = false;
            this.getCardList();
          }
        })
        .catch((e) => {
          this.$message.error(e);
          this.isDisable = false;
        });
    },
    getUseRecodsHandler(row) {
      this.$router.push({
        path: 'card/useRecods',
        query: {
          cardNo: row.cardNo,
        },
      });
    },
    handleConnectorNumChange(e) {
      const matchResult = this.formData.connectorNum.match(/\d{2}/g);
      this.formData.connectorNum = matchResult ? matchResult[0] : '';
    },
    showQrcode(row) {
      console.log(process.env);
      this.codeDialogVisible = true;
      this.$nextTick(() => {
        const container = this.$refs.qrcodeContainer;
        this.currentCodeUrl =
          this.global.baseUrl + '/mp?scanid=' + row.connectorCode;
        QRCode.toCanvas(container, this.currentCodeUrl, (err) => {
          if (err) console.error(err);
          else console.log('QR code generated successfully');
        });
      });
    },
    downloadQRCode() {
      const canvas = this.$refs.qrcodeContainer;
      const dataUrl = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'qrcode.png';
      link.click();
    },
    hanldeCopyCodeUrl() {
      this.$copyText(this.currentCodeUrl).then((e) => {
        this.$message.success('复制成功');
        console.log(e);
      });
    },
  },
};
</script>
<style scoped>
.title {
  display: flex;
  flex-direction: row;
}
</style>
