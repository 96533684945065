<template>
  <div class="main">
    <div class="content-box">
      <div class="title">
        <el-form ref="serachForm" :model="searchForm" label-suffix=":" label-width="120px">
          <el-row :gutter="20" type="flex" style="flex-wrap:wrap;">
            <el-col :span="8">
              <el-form-item label="电表编号">
                <el-input v-model="searchForm.ammeterNo" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电表名称">
                <el-input v-model="searchForm.ammeterName" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="通讯状态">
                <el-select v-model="searchForm.stat" placeholder="请选择" style="width: 100%;" clearable
                  @change="handleSelectOperator">
                  <el-option v-for="(item, index) in messageStat" :key="index" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20" type="flex" style="flex-wrap:wrap;">
            <el-col :span="8" v-if="isOperator != 1">
              <el-form-item label="运营商名称">
                <el-select v-model="searchForm.realName" filterable placeholder="请选择" style="width: 100%;" clearable>
                  <el-option v-for="(item, index) in operatorList" :key="index" :label="item.realName" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="站点名称">
                <el-select v-model="searchForm.plotId" multiple placeholder="请选择" style="width: 100%;"
                  @change="handleSelectState(searchForm.plotId)" clearable>
                  <el-option v-for="(item, index) in plotList" :key="index" :label="item.plotName"
                    :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="isOperator == 1 ? 16 : 8">
              <div style="float: right">
                <el-button @click="searchData" type="primary" style="margin-left:20px;"
                  icon="el-icon-search">查询</el-button>
                <el-button @click="resetData" plain style="margin-left:20px;" icon="el-icon-refresh">重置</el-button>
              </div>
            </el-col>
          </el-row>

        </el-form>
      </div>
    </div>
    <div class="content-box">
      <div style="display: flex;justify-content: space-between; align-items: center;margin-bottom: 12px;">
        <div style="overflow: hidden">
          <el-button type="primary" icon="el-icon-plus" @click="handleAddPowerData"
            v-show="isOperator != 1">新增</el-button>
        </div>
        <div v-if="offline != 0" style="text-align: right; color: #6b5757;font-size: 14px;">共 <span
            style="color: rgba(223, 55, 55, 1);">{{ offline }} </span>个离线</div>
      </div>
      <el-table v-loading="loading" border :data="tableData" style="width: 100%">
        <el-table-column type="index" label="序号" align="center" width="60" fixed></el-table-column>
        <el-table-column prop="userName" label="运营商名称" align="center" min-width="130" v-if="isOperator != 1"
          fixed></el-table-column>
        <el-table-column prop="ammeterNo" label="电表编号" align="center" min-width="100" show-overflow-tooltip
          fixed></el-table-column>
        <el-table-column prop="ammeterName" label="电表名称" align="center" min-width="120" show-overflow-tooltip
          fixed></el-table-column>
        <el-table-column prop="ammeterBrand" label="电表品牌" align="center" min-width="130" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-if="scope.row.ammeterBrand != ''" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{ scope.row.ammeterBrand }}</div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column prop="ammeterModel" label="电表型号" align="center" min-width="130" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-if="scope.row.ammeterModel != ''" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{ scope.row.ammeterModel }}</div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column prop="stat" align="center" label="通讯状态" min-width="130">
          <template slot-scope="{ row }">
            <!-- <el-switch v-model="!row.state" disabled> </el-switch> -->
            <div style="display: flex;justify-content: center;">
              <div v-if="row.stat == 0"
                style="width:50px;height: 30px; padding: 4px 4px;border-radius: 4px;color: #DF3737; background: rgba(223, 55, 55, 0.06);">
                离线</div>
              <div v-if="row.stat == 1"
                style="width:50px;height: 30px;padding: 4px 4px;border-radius: 4px;color: #DF3737;background: rgba(223, 55, 55, 0.06);">
                故障</div>
              <div v-if="row.stat == 2"
                style="width:50px;height: 30px;padding: 4px 4px;border-radius: 4px;color: #057C98;background: rgba(223, 55, 55, 0.06);">
                在线</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="wireMethod" label="接线方式" align="center" min-width="100">
          <template slot-scope="{ row }">
            <!-- <el-switch v-model="!row.state" disabled> </el-switch> -->
            <div v-if="row.wireMethod == 1">单相单线</div>
            <div v-if="row.wireMethod == 2">三相三线</div>
            <div v-if="row.wireMethod == 3">三相四线</div>
          </template>
        </el-table-column>
        <el-table-column prop="plotName" label="站点名称" align="center" min-width="100"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="installPosition" label="安装位置" align="center" min-width="130" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-if="scope.row.installPosition != ''" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{ scope.row.installPosition }}</div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column prop="positionPics" label="安装位置图片" align="center" min-width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.positionPics[0] != ''" style="cursor: pointer;color: #057C98;"
              @click="handlePreviewImages(scope.row)">查看图片</div>
            <div v-else style="cursor: pointer;color: #057C98;">-</div>
          </template>
          <!-- <template slot-scope="scope">
            <el-image v-for="(img_url, index_url) in scope.row.positionPics" :key="index_url" style="height: 40px"
              :src="img_url" :preview-src-list="[img_url]">
            </el-image>
          </template> -->
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center" min-width="100"
          show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" align="center" :width="isOperator == 1 ? 0 : 180" fixed="right">
          <template slot-scope="scope">
            <div class="editCell" v-if="isOperator != 1">
              <!-- <el-button @click="handlerReadyLog(scope.row)">电表数据</el-button> -->
              <el-button type="primary" @click="handlerEditAction(scope.row)">编辑</el-button>
              <el-button type="danger" @click="handlerDelItem(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
        :page-size="listPageSize" layout="total, prev, pager, next,sizes, jumper" :total="total"></el-pagination>
    </div>

    <!-- 新增 && 编辑  -->
    <el-dialog id="popupMoal" :title="addDialogTitle" :visible.sync="dialogVisibleAdd" width="752px"
      @close="handleDialogAddClose">
      <el-form ref="formDataAddRef" :model="formDataAdd" label-position="left" :rules="formDataAddRule">
        <div>
          <div class="form-item-content">
            <el-form-item class="nav-form-item" prop="ammeterName">
              <div> <span style="color:#DF3737;">*</span> 电表名称</div>
              <div><el-input maxlength="30" v-model="formDataAdd.ammeterName" style="width:340px;" placeholder="请输入"
                  show-word-limit clearable /></div>
            </el-form-item>
            <el-form-item class="nav-form-item" label="" prop="ammeterNo">
              <div> <span style="color:#DF3737;">*</span> 电表编号</div>
              <div><el-input maxlength="30" v-model="formDataAdd.ammeterNo" style="width:340px;"
                  placeholder="填写电表实际序列号(电表唯一编码)" show-word-limit clearable /></div>
            </el-form-item>
          </div>
          <div>
            <div class="form-item-content">
              <el-form-item class="nav-form-item" label="电表品牌">
                <el-input v-model="formDataAdd.ammeterBrand" :maxlength="30" placeholder="请输入" style="width:340px;"
                  show-word-limit></el-input>
                <!-- <el-select v-model="formDataAdd.ammeterBrand" placeholder="请选择" style="width:240px;">
                <el-option v-for="(brandItem, brandIndex) in ammeterBrandOption" :key="brandIndex"
                  :label="brandItem.label" :value="brandItem.value"></el-option>
              </el-select> -->
              </el-form-item>
              <el-form-item class="NumberType nav-form-item" label="电表型号">
                <el-input v-model="formDataAdd.ammeterModel" :maxlength="30" placeholder="请输入" style="width:340px;"
                  show-word-limit></el-input>
                <!-- <el-select v-model="formDataAdd.ammeterModel" placeholder="请选择" style="width:240px;">
                <el-option v-for="(modelItem, modelIndex) in ammeterModelOption" :key="modelIndex"
                  :label="modelItem.label" :value="modelItem.value"></el-option>
              </el-select> -->
              </el-form-item>
            </div>
            <div class="form-item-content">
              <el-form-item class="NumberType nav-form-item" label="接线方式" prop="wireMethod">
                <el-select v-model="formDataAdd.wireMethod" placeholder="请选择" style="width:340px;"
                  :disabled="addDialogTitle[0] == '编' ? true : false">
                  <el-option v-for="(item_w, index_w) in wireMethodOption" :key="index_w" :label="item_w.label"
                    :value="item_w.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="NumberType nav-form-item" label=" 站点名称" prop="plotId">
                <el-select v-model="formDataAdd.plotId" placeholder="关联站点" style="width:340px;"
                  @change="handleSelPlotVal">
                  <el-option v-for="(item, index) in addSelPlotList" :key="index" :label="item.plotName"
                    :value="item.id"></el-option>
                </el-select>
                <div v-if="isInsertEdit == 1 && showEditTipText && addDialogTitle[0] == '编'"
                  style="color:#F56C6C;font-size: 12px;">
                  电表已加入有序充电-{{ delItamPowerlineName }}中应用，变更站点后，线路层级中的电表及其下的支点将一并从{{ delItamPowerlineName }}中移除</div>
              </el-form-item>
            </div>
            <div class="form-item-content">
              <el-form-item class="nav-form-item" key="3" label="安装位置">
                <el-input type="textarea" v-model="formDataAdd.installPosition" :maxlength="150" placeholder="请输入"
                  rows="7" style="width:340px;" show-word-limit></el-input>
              </el-form-item>
              <el-form-item class="nav-form-item" label="" prop="fileId" style="width:340px;">
                <div>
                  安装位置图片
                </div>
                <div>
                  <el-upload 
                  :limit="9" 
                  :action="uploadUrl" 
                  :headers="headers"
                  :data="uploadData" 
                  list-type="picture-card"
                  :on-change="handleStartChange" 
                  :on-success="handleFileSuccess" 
                  :on-exceed="handleImgExceed" 
                  :file-list="showImgList"
                  multiple>
                    <i slot="default" class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{file}" style="width: 100%;height: 100%;">
                      <img class="el-upload-list__item-thumbnail" :src="file.response.data.imgUrl" alt="">
                      <span class="el-upload-list__item-actions">
                        <span v-if="!disabledImg" class="el-upload-list__item-delete" @click="handleRemove(file)">
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                     
                    </div>
                  </el-upload>
                  <div class="el-upload__tip" slot="tip" style="margin-top: 0; font-size: 10px; color: #86909C;">
                      图片用于定位电表安装位置；尺寸不限，jpg/png格式，最多九张
                    </div>
                </div>

                <div>
                  <!-- show img -->
                  <!-- <div style="width: 100%;height: 100%;" v-if="showImgListContent">
                      <img class="el-upload-list__item-thumbnail" style="width: 60px;height: 60px;" v-for="(item,index) in showImgList" :key="index" :src="item.response.data.imgUrl" alt="">
                      <span class="el-upload-list__item-actions">
                        <span v-if="!disabledImg" class="el-upload-list__item-delete" @click="handleRemove(file)">
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                    </div>
                  <el-upload ref="upload" class="upload-demo" drag :limit="9" :action="uploadUrl" :headers="headers"
                    :data="uploadData" :on-success="handleFileSuccess" :show-file-list="true" :on-remove="handleRemove"
                    :file-list="showImgList" :on-change="handleStartChange" :on-exceed="handleImgExceed" multiple
                    style="width:340px;">
                    
                    <i class="el-icon-plus" style="width: 60px;"></i>
                    <div class="el-upload__tip" slot="tip" style="margin-top: 0; color: #86909C;">
                      图片用于定位电表安装位置；尺寸不限，jpg/png格式，最多九张
                    </div>
                  </el-upload> -->
                </div>
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleAdd = false">取 消</el-button>
        <el-button type="primary" @click="confimHandler" :disabled="isDisabled" key="save">保 存</el-button>
      </span>
    </el-dialog>

    <!-- 电表数据 -->
    <el-dialog id="popupLogMoal" title="电表数据" :visible.sync="dialogVisibleLog" width="650px" @close="handleDialogClose">
      <el-table v-loading="loading" border :data="plotIdTableData" style="width: 100%">
        <el-table-column type="index" label="序号" align="center" width="60"></el-table-column>
        <el-table-column prop="userName" label="时间" align="center" min-width="130"></el-table-column>
        <el-table-column prop="ammeterNo" label="总（度）" align="center" min-width="100"
          :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="ammeterName" label="A相电流（A）" align="center" min-width="100"></el-table-column>
        <el-table-column prop="ammeterBrand" label="C相电流（C）" align="center" min-width="100"></el-table-column>
        <el-table-column prop="ammeterModel" label="剩余电流（A）" align="center" min-width="100"></el-table-column>
        <el-table-column prop="stat" align="center" label="总功率（kW）" min-width="130">
          <template slot-scope="{ row }">
            <!-- <el-switch v-model="!row.state" disabled> </el-switch> -->
            <div v-if="row.stat == 0">离线</div>
            <div v-if="row.stat == 1">故障</div>
            <div v-if="row.stat == 2">在线</div>
          </template>
        </el-table-column>
        <el-table-column prop="plotName" label="A相功率（kW）" align="center" min-width="100"></el-table-column>
        <el-table-column prop="installPosition" label="B相功率（kW）" align="center" min-width="100"></el-table-column>
        <el-table-column prop="positionPics" label="C相功率（kW）" align="center" min-width="100"></el-table-column>
        <el-table-column prop="createTime" label="A相电压（V）" align="center" min-width="100"></el-table-column>
        <el-table-column prop="createTime" label="B相电压（V）" align="center" min-width="100"></el-table-column>
        <el-table-column prop="createTime" label="C相电压（V）" align="center" min-width="100"></el-table-column>
      </el-table>
    </el-dialog>

    <!-- 预览图片 -->
    <el-dialog :visible.sync="dialogVisibleImage" title="安装位置图片预览">
      <!-- <el-carousel trigger="click" type="card" :loop="false">
        <el-carousel-item v-for="(image, index) in currentImages" :key="index">
          <img :src="image" alt="图片">
        </el-carousel-item>
      </el-carousel> -->

      <el-carousel class="image-swiper" :height="'100%'" :indicator-position="currentImages.length <= 1 ? 'none' : ''"
        :arrow="currentImages.length <= 1 ? 'never' : ''" :autoplay="false">
        <el-carousel-item v-for="(image, $i) in currentImages" :key="$i">
          <el-image style="width: 100%; height: 100%" :src="image" :preview-src-list="currentImages.map(v => v)"
            :initial-index="$i" :fit="'cover'">
          </el-image>
        </el-carousel-item>
      </el-carousel>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleImage = false">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 删除列表 -->
    <el-dialog :title="'确定删除电表：' + delItamPowerName" :visible.sync="dialogVisibleDel" width="30%">
      <div style="margin-bottom: 20px;font-size: 16px;">此操作不可逆，请确认电表未使用后再谨慎操作</div>
      <div v-if="isInsert == 1" style="line-height: 20px;font-weight: bold;font-size: 14px;color:#F56C6C;">
        电表已加入 有序充电-{{ delItamPowerlineName }} 中应用，删除电表后，线路层级中的电表及其下的支点将一并从 {{ delItamPowerlineName }} 移除</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleDel = false">取 消</el-button>
        <el-button type="primary" @click="handleSureDel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getPowerList,
  getAmmeterList,
  getOperatorList,
  getPlotList,
  addAmmeter,
  delAmmeterItem,
  updateAmmeterItem
} from "@/api/power";

import { getName, getToken } from '@/utils/auth.js'

export default {
  data() {
    var validatePhone = (rule, value, callback) => { // 手机号验证
      const reg = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
      if (reg.test(value)) {
        callback();
      } else {
        return callback(new Error('请输入正确的手机号'));
      }
    };

    var checkPhoneFull = (rule, value, callback) => {
      // 假设手机号格式是 13X XXXX XXXX，其中 X 是任意数字
      // 这里我们简单校验长度，实际中可能需要更复杂的正则
      const fullPhone = `${this.form.phonePart1}${this.form.phonePart2}1${this.form.phonePart3}`;
      if (fullPhone.length !== 11 || !/^13\d{9}$/.test(fullPhone)) {
        callback(new Error('手机号格式不正确'));
      } else {
        callback();
      }
    };

    var validateSpace = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('电表名称不能为空'));
      } else if (value.trim() === '') {
        callback(new Error('电表名称不能为空'));
      } else {
        callback();
      }
    };
    return {
      isDisabled: false,
      loading: false,
      disabledImg: false,
      showImgListContent: false,
      isOperator: '',
      ammeterName: '',
      ammeterNo: '',
      operatorId: '',
      pageNo: 1,
      listPageSize: 20,
      pageSize: 10,
      plotId: null,
      stat: '',
      offline: '',
      tableData: [],
      total: 0,
      ammeterNoList: [],
      operatorList: [],
      plotList: [],
      addSelPlotList: [],
      searchForm: {
        ammeterNo: '',
        ammeterName: '',
        operatorId: '',
        plotId: null,
        stat: '',
        realName: '',
      },
      messageStat: [{
        value: 0,
        label: '离线'
      }, {
        value: 1,
        label: '故障'
      }, {
        value: 2,
        label: '在线'
      }],
      wireMethodOption: [{
        value: 1,
        label: '单相单线'
      }, {
        value: 2,
        label: '三相三线'
      }, {
        value: 3,
        label: '三相四线'
      }],
      ammeterBrandOption: [
        {
          value: '雅达',
          label: '雅达'
        }
      ],
      ammeterModelOption: [
        {
          value: 'DTSD3366D-4P-MX',
          label: 'DTSD3366D-4P-MX'
        }
      ],
      page: 1,
      addDialogTitle: '',
      dialogVisibleAdd: false,
      dialogVisibleStop: false,
      dialogVisibleLog: false,
      dialogVisibleImage: false,
      dialogVisibleDel: false,
      showEditTipText: false,
      currentImages: [],
      uploadUrl: process.env.VUE_APP_BASE_API + "/file/uploadImg",
      headers: {
        token: getToken(),
      },
      uploadData: {
        business: "advert",
      },
      showImgList: [],
      imageUrl: "",
      formDataAdd: {
        "ammeterBrand": "",
        "ammeterModel": "",
        "ammeterName": "",
        "ammeterNo": "",
        "id": 0,
        "installPosition": "",
        "plotId": null,
        "positionPics": "",
        "remark": "",
        "wireMethod": 3
      },
      formDataAddRule: {
        ammeterName: [
          { required: true, message: "请输入电表名称", trigger: "blur" },
          { validator: validateSpace, trigger: 'blur' },
        ],
        ammeterNo: [
          { required: true, message: "请输入电表编号", trigger: "blur" },
        ],
        wireMethod: [
          { required: true, message: "请选择接线方式", trigger: "blur" },
        ],
        plotId: [
          { required: true, message: "请选择站点名称", trigger: "blur" },
        ]
      },
      isInsert: '',
      isInsertEdit: '',
      delItamPowerId: '',
      delItamPowerName: '',
      delItamPowerlineName: '',
      plotIdTableData: []
    };
  },
  created() {
    this.getPowerList();
    this.getOperatorList();
    this.getPlotList();
    this.getShowSelPlotList();
  },
  mounted() {
    //isOperator为0代表不可操作，1可操作属于运营人员
    let getIsoperator = JSON.parse(getName())
    this.isOperator = getIsoperator.userInfo.isOperator
    console.log(this.isOperator);
  },
  methods: {
    getPowerList() { //获取电表列表数据
      this.loading = true;
      let param = {
        ammeterName: this.ammeterName,
        ammeterNo: this.ammeterNo,
        operatorId: this.operatorId,
        plotId: this.plotId,
        stat: this.stat,
        pageNo: this.pageNo,
        pageSize: this.listPageSize,
      };
      getPowerList(param).then((res) => {
        if (res.message.status == 200) {
          //图片转换
          if (res.data.page.records.length > 0) {
            res.data.page.records.forEach(item => {
              item.positionPics = item.positionPics.split(',')
            })
          }
          this.tableData = [...res.data.page.records];
          this.total = res.data.page.total;
          this.offline = res.data.offline;
        }
        this.loading = false;
      });
    },
    getOperatorList() { //获取运营商
      let param = {
        keyWord: this.searchForm.operatorName,
        pageNo: 0,
        pageSize: 10,
      }
      getOperatorList(param).then((res) => {
        if (res.data && res.data.records.length > 0) {
          this.operatorList = res.data.records;
        }
      });
    },
    getPlotList() { //获取站点
      let param = {
        keyWord: '',
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      getPlotList(param).then((res) => {
        if (res.data && res.data.records.length >= 0) {
          this.plotList = res.data.records
        }
      });
    },
    getShowSelPlotList() { //新增获取站点
      let param = {
        keyWord: '',
        pageNo: 1,
        pageSize: 500,
      };
      getPlotList(param).then((res) => {
        if (res.data && res.data.records.length >= 0) {
          this.addSelPlotList = res.data.records
          this.addSelPlotList = this.addSelPlotList.filter(item => item.id = parseInt(item.id))
        }
      });
    },
    handleSelPlotVal() {
      // 编辑提示
      this.showEditTipText = true
    },
    handlePreviewImages(obj) {
      this.currentImages = obj.positionPics; // 设置当前要显示的图片列表
      this.dialogVisibleImage = true; // 打开对话框
    },
    handleFileSuccess(response, file, fileList) {
      // 成功上传图片后的处理逻辑
      console.log('图片上传成功', response, file, fileList);
      // this.showImgList = []
      // let changeFileList = fileList
      // changeFileList.forEach(item => {
      //   this.showImgList.push({imgUrl: item.response.data.imgUrl})
      // })
      // console.log(this.showImgList,7943);
      this.showImgList = fileList
      this.isDisabled = this.isDisabled
      // this.showImgList.push({
      //   uid: file.uid,
      //   name: file.name,
      //   url: response.data.imgUrl
      // })
      // this.$refs.upload.clearFiles();
    },
    handleRemove(file) {
      const index = this.showImgList.findIndex(f => f.name === file.name);
      if (index > -1) {
        this.showImgList.splice(index, 1);
      }

      // if (fileList.length == 1) {
      //   this.formDataAdd.positionPics = fileList.map(obj => obj.url).join('')
      // } else if (this.formDataAdd.positionPics.length > 1) {
      //   this.formDataAdd.positionPics = fileList.map(obj => obj.url).join(',')
      // }
    },
    handleStartChange(response, file) {
      this.showImgList = file
      this.isDisabled = !this.isDisabled
    },
    handleImgExceed() {
      this.$message.warning('最多上传9张图片')
    },
    confimHandler() { //新增 & 编辑
      console.log(this.showImgList,789798);
      this.$refs.formDataAddRef.validate((valid) => {
        if (valid) {
          if (this.addDialogTitle == '编辑电表') {//编辑
            let changeImgUrl = []
            if (this.showImgList.length > 0) {
              this.showImgList.forEach(item => {
                if (item.response && item.response.data) {
                  changeImgUrl.push(item.response.data.imgUrl)
                } else {
                  changeImgUrl.push(item.imgUrl)
                }
              })
            }
            this.formDataAdd.positionPics = changeImgUrl.join(',')
            updateAmmeterItem(this.formDataAdd).then((res) => {
              if (res.message.status == 200) {
                this.$message({
                  message: '保存成功！',
                  type: 'success'
                });
                this.dialogVisibleAdd = false
                this.showEditTipText = false
                this.getPowerList();
              } else {
                this.$message(res.message.message);
              }
            }).catch(err => {
              this.$message(err);
            })
          } else { //新增
            let changeImgUrl = []
            this.showImgList.forEach(item => {
              changeImgUrl.push(item.response.data.imgUrl)
            })
            this.formDataAdd.positionPics = changeImgUrl.join(',')
            addAmmeter(this.formDataAdd).then((res) => {
              if (res.message.status == 200) {
                this.$message({
                  message: '保存成功！',
                  type: 'success'
                });
                this.dialogVisibleAdd = false
                this.getPowerList();
              } else {
                this.$message(res.message.message);
              }
            }).catch(err => {
              this.$message(err);
            })
          }
        }
      })
    },
    handleSelectPartner(partnerName) {
      this.searchForm.partnerName = partnerName
    },
    handleSelectState(e) {
      if (e) {
        this.selectPartState = 0
      } else if (e === false) {
        this.selectPartState = 1
      } else if (e === '') {
        this.selectPartState = '空'
      }
    },
    handleSelectOperator(operatorId) {
      const selectedOption = this.operatorList.find(option => option.id == operatorId)
      if (selectedOption) this.searchForm.operatorName = selectedOption.realName;
      this.operatorId = operatorId
    },
    searchData() { //查询
      console.log('查询', this.searchForm)
      this.ammeterName = this.searchForm.ammeterName
      this.ammeterNo = this.searchForm.ammeterNo
      this.operatorId = this.searchForm.realName
      this.plotId = this.searchForm.plotId ? this.searchForm.plotId.join(',') : ''
      this.stat = this.searchForm.stat
      this.pageSize = 10
      this.pageNo = 1;
      this.getPowerList();
    },
    resetData() { //重置
      this.ammeterName = ''
      this.ammeterNo = ''
      this.operatorId = ''
      this.plotId = ''
      this.stat = ''
      this.searchForm.ammeterNo = ''
      this.searchForm.ammeterName = ''
      this.searchForm.operatorId = ''
      this.searchForm.plotId = null
      this.searchForm.stat = ''
      this.searchForm.realName = ''
      this.pageSize = 10
      this.pageNo = 1;
      this.getPowerList();
    },
    handleChangeState(e) {
      this.isThreadsStop = e ? true : false
    },
    handleDialogClose() {
      this.isThreadsStop = false
    },
    handleSizeChange(val) {
      this.listPageSize = val;
      this.getPowerList();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.page = val;
      this.getPowerList();
    },
    handleAddPowerData() { //新增电表
      this.showImgList = []
      this.formDataAdd.ammeterBrand = ''
      this.formDataAdd.ammeterModel = ''
      this.formDataAdd.ammeterName = ''
      this.formDataAdd.ammeterNo = ''
      this.formDataAdd.id = ''
      this.formDataAdd.installPosition = ''
      this.formDataAdd.plotId = ''
      this.formDataAdd.positionPics = ''
      this.formDataAdd.wireMethod = 3
      this.addDialogTitle = '新增电表'
      this.isThreadsStop = false
      this.dialogVisibleAdd = true
    },
    handleDialogAddClose() { //清空校验
      this.$refs.formDataAddRef.clearValidate();
    },
    handlerReadyLog(row) {
      getAmmeterList({ plotId: row.id }).then((res) => {
        if (res.data && res.data.page.records.length >= 0) {
          this.plotIdTableData = [...res.data.page.records]
        }
      });
      this.dialogVisibleLog = true
    },


    handlerEditAction(row) {
      console.log(row, 6663333)
      this.showImgList = []
      if (row.positionPics[0] != '') {
        // const showListArray = row.positionPics.map(url => { return { imgUrl: url, name: url } })
        const showListArray = row.positionPics.map(url => { return {response: {data:{imgUrl: url}}} })
        this.showImgList = showListArray
        console.log(this.showImgList,789679463);
      }
      this.formDataAdd = Object.assign({}, row);
      this.delItamPowerlineName = row.lineName
      this.isInsertEdit = row.isInsert
      this.addDialogTitle = '编辑电表'
      this.showEditTipText = false
      this.dialogVisibleAdd = true
    },
    handlerDelItem(row) { //删除列表
      console.log(row)
      this.isInsert = row.isInsert
      this.delItamPowerId = row.id
      this.delItamPowerName = row.ammeterName
      this.delItamPowerlineName = row.lineName
      this.dialogVisibleDel = true
    },
    handleSureDel() {
      delAmmeterItem({ id: this.delItamPowerId }).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.dialogVisibleDel = false;
          this.getPowerList();
        } else {
          this.$message.error(res.data);
        }
      });
    }
  },
};
</script>
<style scoped lang="scss">
.el-dropdown-link {
  cursor: pointer;
  margin-left: 10px;
  color: var(--color);
}

.el-icon-arrow-down {
  font-size: 12px;
}

.el-dropdown-menu__item {
  text-align: center;
}


// 弹框计算器隐藏
.NumberType ::v-deep input::-webkit-outer-spin-button,
.NumberType ::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.NumberType ::v-deep input[type="number"] {
  -moz-appearance: textfield;
}

.threads-stop {
  .stop-remark {
    line-height: 22px;
  }
}

.image-swiper {
  width: 400px;
  height: 300px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

::v-deep .el-upload-dragger {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F7F8FA;
}

::v-deep .el-upload-dragger .el-icon-upload {
  margin: 20px 0 16px;
}

//弹框样式
::v-deep .el-dialog {
  border-radius: 8px;
}

::v-deep .el-dialog__title {
  color: #1D2129;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #E5E6EB;
}

.form-item-content {
  display: flex;
  justify-content: space-between;
}

::v-deep .el-upload--picture-card {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #E5E6EB;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 60px;
  height: 60px;
}
</style>
