<template>
  <div class="main">
    <div class="content-box">
      <div class="title">
        <el-select
          clearable
          v-model="operatorId"
          placeholder="请选择代理商"
          clearfix
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.operatorNm"
            :value="item.operatorId"
          ></el-option>
        </el-select>
        <el-input
          maxlength="15"
          style="width:300px;margin-left:20px;"
          placeholder="搜索站点名称/电桩编码/电桩名称"
          v-model="keyWord"
          clearfix
        >
          <el-button
            slot="suffix"
            @click="searchData"
            type="primary"
            icon="el-icon-search"
            style="border-radius:0 4px 4px 0;margin-right:-5px"
            >搜索
          </el-button>
        </el-input>

        <el-button
          @click="addHandler"
          type="primary"
          icon="el-icon-plus"
          style="margin-left:20px;"
          >新增</el-button
        >
        <!--            <el-button @click="changeallPort" type="primary" style="margin-left:20px;" class="switch-btn">-->
        <!--                <img src="@/../public/images/switch.png">-->
        <!--                开启/关闭电桩-->
        <!--            </el-button>-->
        <!-- <el-button
          style="margin-left:20px;"
          class="import-btn"
          type="primary"
          @click="exporthandler"
        >
          <img src="@/../public/images/import.png" />
          导出
        </el-button> -->
      </div>
    </div>
    <div class="content-box">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="80"
          fixed
        ></el-table-column>
        <el-table-column
          prop="pileCode"
          label="电桩编码"
          align="center"
          min-width="140"
          fixed
          show-overflow-tooltip
        ></el-table-column>

        <el-table-column
          prop="pileName"
          label="电桩名称"
          align="center"
          min-width="120"
          fixed
          :show-overflow-tooltip="true"
        ></el-table-column>
        <!--                <el-table-column prop="portCount" label="电枪数" align="center" min-width="60"></el-table-column>-->
        <el-table-column
          prop="pileType"
          align="center"
          label="电桩类型"
          :formatter="cpPileType"
          min-width="80"
          fixed
        ></el-table-column>

        <el-table-column
          prop="plotName"
          align="center"
          label="站点名称"
          min-width="140"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="realName"
          align="center"
          label="运营商"
          min-width="100"
        ></el-table-column>

        <!--                <el-table-column prop="s" align="center" label="运行状态 " :formatter="forState" min-width="80"></el-table-column>-->
        <!--                <el-table-column prop="deviceType" align="center" label="设备类型 " :formatter="cpDeviceType" min-width="80"></el-table-column>-->
        <el-table-column
          prop="pileStatus"
          align="center"
          label="状态"
          :formatter="cpPileStatus"
          min-width="80"
        ></el-table-column>

        <el-table-column
          prop="runningStatus"
          align="center"
          label="运行状态"
          :formatter="forState"
          min-width="80"
        ></el-table-column>

        <!-- <el-table-column prop="pileStatus" align="center" label="在线状态 " :formatter="runningStatus"
                         min-width="80"></el-table-column> -->

        <el-table-column
          prop="totalPowerConsumption"
          label="总耗电量"
          align="center"
          min-width="80"
        ></el-table-column>
        <!--                <el-table-column prop="equipTemperature" label="设备温度" align="center" min-width="80"></el-table-column>-->
        <!--                <el-table-column prop="equipCpuTemperature" label="cpu温度" align="center" min-width="80"></el-table-column>-->
        <!--                <el-table-column prop="simCard" label="SIM卡序列号" align="center" min-width="100"></el-table-column>-->
        <!--                <el-table-column prop="simDueTime" label="SIM卡到期时间" align="center" min-width="110" :show-overflow-tooltip="true"></el-table-column>-->
        <!--                <el-table-column prop="useCount" label="使用频率" align="center" min-width="100"></el-table-column>-->
        <!--                <el-table-column prop="cpFeeType" :formatter="cpFeeType" label="是否收费" align="center"-->
        <!--                                 min-width="80"></el-table-column>-->
        <el-table-column
          label="操作"
          align="center"
          width="320"
          class="editCell"
          fixed="right"
        >
          <template slot-scope="scope">
            <div class="editCell">
              <!--            <el-link @click="price(scope.row)" icon="el-icon-edit">价格编辑</el-link>-->
              <el-button type="success" @click="ruleEditHandler(scope.row)"
                >计费设置</el-button
              >
              <!-- <el-button type="primary" @click="qrIssuedHandler(scope.row)"
                >二维码下发</el-button
              > -->
              <el-button type="primary" @click="editCardHandler(scope.row)"
                >查看/编辑</el-button
              >
              <el-button @click="port(scope.row)">电枪管理</el-button>
              <!-- <el-link @click="deleteHandler(scope.rosw)">删除</el-link> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      id="rulePopupModal"
      title="规则编辑"
      :visible.sync="ruleDialogVisible"
      width="400px"
    >
      <el-form
        ref="form_rule"
        :model="ruleFormData"
        label-width="150px"
        :rules="ruleFormRule"
      >
        <el-form-item class="nav-form-item" label="请选择规则" prop="wxRuleId">
          <el-select
            v-model="ruleFormData.wxRuleId"
            style="width:150px"
            placeholder="请选择规则"
          >
            <el-option
              v-for="item in gzhList"
              :label="item.changeName"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
          <p
            class="status-name"
            style="color: #1FC3F2;cursor: pointer"
            @click="editRule('wx')"
          >
            <i class="el-icon-plus"></i>新建规则
          </p>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="ruleDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="ruleConfimHandler">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      id="popupMoal"
      :title="popUpModalTitle"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <el-form
        ref="form"
        :model="formData"
        label-width="150px"
        :rules="formRule"
      >
        <div style="display: flex;">
          <el-form-item class="nav-form-item" label="电桩编号 " prop="pileCode">
            <el-input
              maxlength="14"
              :disabled="!isAdd"
              v-model="formData.pileCode"
              style="width:200px;"
              placeholder="请输入电桩编号"
              clearable
            />
          </el-form-item>
          <el-form-item class="nav-form-item" label="电桩名称" prop="pileName">
            <el-input
              maxlength="15"
              v-model="formData.pileName"
              style="width:200px;"
              placeholder="请输入电桩名称"
            />
          </el-form-item>
        </div>
        <div style="display: flex;">
          <el-form-item class="nav-form-item" label="电桩类型" prop="pileType">
            <el-select
              style="width:200px"
              v-model="formData.pileType"
              placeholder="请选择"
            >
              <el-option label="快充" value="1"></el-option>
              <el-option label="慢充" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="nav-form-item"
            label="电流信息(A)"
            prop="electricity"
          >
            <el-input
              type="number"
              maxlength="4"
              max="1000"
              min="1"
              v-model="formData.electricity"
              style="width:200px;"
              placeholder="请输入电流信息"
            />
          </el-form-item>
        </div>
        <div style="display: flex;">
          <el-form-item
            class="nav-form-item"
            label="电压信息(V)"
            prop="voltage"
          >
            <el-input
              type="number"
              maxlength="4"
              max="1000"
              min="1"
              v-model="formData.voltage"
              style="width:200px;"
              placeholder="请输入电压信息"
            />
          </el-form-item>
          <el-form-item class="nav-form-item" label="电枪数" prop="portCount">
            <el-input
              type="number"
              maxlength="3"
              max="100"
              min="1"
              :disabled="!isAdd"
              v-model="formData.portCount"
              style="width:200px;"
              placeholder="请输入电枪数"
            />
          </el-form-item>
        </div>
        <div style="display: flex;">
          <el-form-item
            class="nav-form-item"
            label="最大功率(KW)"
            prop="maxPower"
          >
            <el-input
              type="number"
              maxlength="4"
              max="1000"
              min="1"
              v-model="formData.maxPower"
              style="width:200px;"
              placeholder="请输入最大功率"
            />
          </el-form-item>
          <el-form-item class="nav-form-item" label="运营商" prop="userId">
            <el-select v-model="formData.userId" placeholder="运营商">
              <el-option
                v-for="(item, index) in agentData"
                :key="index"
                :label="item.operatorNm"
                :value="item.operatorId"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div style="display: flex;">
          <el-form-item
            class="nav-form-item"
            label="SIM卡序列号"
            prop="simCard"
          >
            <el-input
              maxlength="20"
              v-model="formData.simCard"
              style="width:200px;"
              placeholder="请输入SIM卡序列号"
              type="text"
              onKeyUp="value=value.replace(/[\W]/g,'')"
            />
          </el-form-item>
          <el-form-item label="SIM卡到期时间" prop="simDueTime">
            <el-date-picker
              　　v-model="formData.simDueTime"
              　　type="datetime"
              　　value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              placeholder="请选择SIM卡到期时间"
              style="width:200px;"
              　　
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div style="display: flex;">
          <el-form-item class="nav-form-item" label="状态" prop="name">
            <el-select
              style="width:200px"
              v-model="formData.pileStatus"
              placeholder="请选择"
            >
              <el-option label="启用" value="1"></el-option>
              <el-option label="禁用" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="nav-form-item" label="所属站点" prop="plotName">
            <el-select
              @change="selPlot"
              style="width:120px"
              v-model="polt"
              placeholder="请选择站点"
              v-if="poltList.length"
            >
              <el-option
                v-for="item in poltList"
                :label="item.plotName"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
            <el-input
              maxlength="15"
              v-model="formData.plotName"
              style="width:200px;"
              placeholder="请输入站点名"
              v-else
            />
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button :disabled="isDisable" type="primary" @click="confimHandler"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      id="allPortModal"
      title="电枪开启/关闭"
      :visible.sync="allPortVisible"
      width="30%"
    >
      <el-form
        ref="form_allPort"
        :model="allPortFormData"
        label-width="150px"
        :rules="allPortFormRule"
      >
        <el-form-item class="nav-form-item" label="电桩编号" prop="code">
          <el-input
            maxlength="9"
            max="100"
            v-model="allPortFormData.code"
            style="width:200px;"
            placeholder="请输入电桩编号"
          />
        </el-form-item>
        <el-form-item class="nav-form-item" label="选择开关" prop="type">
          <el-select
            v-model="allPortFormData.type"
            style="width:200px"
            placeholder="请选择开关"
          >
            <el-option
              v-for="item in kgList"
              :label="item.name"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="allPortVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="allPortConfimHandler"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getChargingPileList,
  delPromotion,
  addPile,
  editPile,
  setPileRule,
  setQrIssued,
} from '@/api/pile';
import { getToken } from '@/utils/auth';
import { getProvinceList, getCityList, getPoltListV2 } from '@/api/common';
import { getDataScopeList } from '@/api/user';
import { getOperatorSelect } from '@/api/operator';
import { getRuleList } from '@/api/rule';
import { allPort } from '../../api/port';

export default {
  data() {
    var validateNumber = (rule, value, callback) => {
      var format = /^[1-9]\d*$/;
      if (value === '') {
        callback(new Error('请输入卡号，字母（ABCDEF）+数字（0-9）'));
      } else if (!format.test(value)) {
        callback(new Error('请输入卡号，字母（ABCDEF）+数字（0-9）'));
      } else {
        callback();
      }
    };
    const validatePlot = (rule, value, callback) => {
      if (this.poltList.length) {
        if (this.polt === '') {
          callback(new Error('请选择所属站点'));
        } else {
          callback();
        }
      } else {
        if (value === '') {
          callback(new Error('请选择所属站点'));
        } else {
          callback();
        }
      }
    };
    return {
      url: process.env.VUE_APP_BASE_API,
      polt: '',
      poltList: [],
      area: '',
      areaList: '',
      city: '',
      cityList: [],
      province: '',
      provinceList: [],
      isDisable: false,
      allPortVisible: false,
      dialogVisible: false,
      ruleDialogVisible: false,
      gzhList: [],
      cardList: [],
      operatorId: this.$route.query.operatorId,
      keyWord: '',
      id: '',
      isAdd: true,
      search: '',
      tableData: [],
      page: 1,
      pageSize: 10,
      loading: true,
      allPortFormData: {
        type: '',
        code: '',
      },
      ruleFormData: {
        cardRuleId: '',
        wxRuleId: '',
        pileId: '',
      },
      formData: {
        id: null,
        pileCode: '',
        pileName: '',
        plot: '',
        plotName: '',
        portCount: null,
        simCard: '',
        simDueTime: '',
        userId: '',
        runningStatus: '1',
        deviceType: '4',
        pileStatus: null,
        pileType: '',
        cpFeeType: 2001,
        electricity: '',
        voltage: '',
        maxPower: '',
        regionCode: '',
      },
      total: 0,
      agentData: [],
      options: [],
      kgList: [{ id: '1', name: '打开' }, { id: '0', name: '关闭' }],
      formRule: {
        pileCode: [
          { required: true, message: '请输入电桩编号', trigger: 'blur' },
        ],
        pileName: [
          { required: true, message: '请输入电桩名称', trigger: 'blur' },
        ],
        simCard: [{ required: false, message: '请输入卡号', trigger: 'blur' }],
        portCount: [
          {
            validator: validateNumber,
            required: true,
            message: '请输入电枪数,正整数',
            trigger: 'blur',
          },
        ],
        simDueTime: [
          { required: false, message: '请输入到期时间', trigger: 'blur' },
        ],
        runningStatus: [
          { required: true, message: '请选择运行状态', trigger: 'blur' },
        ],
        deviceType: [
          { required: true, message: '请选择设备类型', trigger: 'blur' },
        ],
        electricity: [
          {
            validator: validateNumber,
            required: true,
            message: '请输入电流信息,正整数',
            trigger: 'blur',
          },
        ],
        voltage: [
          {
            validator: validateNumber,
            required: true,
            message: '请输入电压信息,正整数',
            trigger: 'blur',
          },
        ],
        maxPower: [
          {
            validator: validateNumber,
            required: true,
            message: '请输入最大功率,正整数',
            trigger: 'blur',
          },
        ],
        pileStatus: [
          { required: true, message: '请选择状态', trigger: 'blur' },
        ],
        pileType: [
          { required: true, message: '请选择电桩类型', trigger: 'blur' },
        ],
        userId: [{ required: true, message: '请选择运营商', trigger: 'blur' }],
        plotName: [
          {
            validator: validatePlot,
            required: true,
            message: '请选择所属站点',
            trigger: 'blur',
          },
        ],
      },
      allPortFormRule: {
        id: [{ required: true, message: '请输入电桩编号', trigger: 'blur' }],
        type: [{ required: true, message: '请选择选择开关', trigger: 'blur' }],
      },
      ruleFormRule: {
        wxRuleId: [
          { required: true, message: '请选择公众号规则', trigger: 'blur' },
        ],
        cardRuleId: [
          { required: true, message: '请选择卡规则', trigger: 'blur' },
        ],
      },
      props: {
        value: 'id',
        label: 'name',
      },
      popUpModalTitle: '新增',
      exportParams: {},
    };
  },
  created() {
    this.getDataScopeList();
    //this.getProvinceList()
    this.selArea();
    this.getDataList();
    this.getAgentList();
  },
  watch: {
    polt: function(v) {
      this.formData.plot = v;
    },
    'formData.portCount': {
      handler: function(v) {
        if (v > 100) {
          this.formData.portCount = 100;
        }
      },
    },
  },
  methods: {
    forState(row) {
      if (row.runningStatus == 0) {
        return '运行';
      } else if (row.runningStatus == 1) {
        return '离线';
      }
    },
    cpFeeType(row) {
      if (row.cpFeeType == 2001) {
        return '收费';
      } else if (row.cpFeeType == 2002) {
        return '免费';
      }
    },
    cpDeviceType(row) {
      if (row.deviceType == 2) {
        return '二轮车';
      } else if (row.deviceType == 4) {
        return '四轮车';
      }
    },
    cpPileType(row) {
      if (row.pileType == 1) {
        return '快充';
      } else if (row.pileType == 0) {
        return '慢充';
      }
    },
    cpPileStatus(row) {
      if (row.pileStatus == 1) {
        return '启用';
      } else if (row.pileStatus == 0) {
        return '禁用';
      }
    },
    runningStatus(row) {
      if (row.runningStatus == 1) {
        return '离线';
      } else if (row.runningStatus == 0) {
        return '在线';
      }
    },
    editRule(type) {
      if (type === 'wx') {
        if (this.ruleFormData.wxRuleId) {
          this.$router.push({
            path: '/rules/list',
            query: {},
          });
        } else {
          this.$message({
            message: '请先选择规则',
            type: 'fail',
          });
        }
      }
      if (type === 'card') {
        if (this.ruleFormData.cardRuleId) {
          this.$router.push({
            path: '/rules/price',
            query: {
              id: this.ruleFormData.cardRuleId,
            },
          });
        } else {
          this.$message({
            message: '请先选择规则',
            type: 'fail',
          });
        }
      }
    },
    getRules(userId) {
      getRuleList({
        ruleType: 10002,
        pageNo: 1,
        pageSize: 20,
        userId: userId,
        deviceType: 4,
      }).then((res) => {
        this.gzhList = [...res.data.records];
      });
      // getRuleList({ruleType: 10001, pageNo: 1, pageSize: 20, userId: userId,deviceType: 4}).then(res => {
      //     this.cardList = [...res.data.records]
      // })
    },
    price(row) {
      if (row.wxRuleId) {
        var id = row.wxRuleId;
      } else {
        alert('暂未分配微信规则');
        return false;
      }
      this.$router.push({
        path: '/rules/price',
        query: {
          id: id,
        },
      });
    },
    selArea(val) {
      let param = {
        regionCode: val,
      };
      this.formData.regionCode = val;
      this.formData.plotName = '';
      getPoltListV2(param).then((res) => {
        this.poltList = [...res.data];
        this.poltList.push({ id: -1, plotName: '自定义输入站点' });
        this.polt = '';
      });
    },
    selPlot(val) {
      if (val == -1) {
        this.poltList = [];
      }
    },
    selCity(val) {
      let param = {
        pid: val,
      };
      getCityList(param).then((res) => {
        this.areaList = [...res.data];
        this.area = '';
      });
    },
    exporthandler() {
      this.$confirm('确定将列表导出吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let xhr = new XMLHttpRequest();
        xhr.open('post', this.url + '/chargingpile/chargeListExport', true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('token', getToken()); // 将token添加到请求头
        // xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function() {
          if (this.status == 200) {
            var blob = this.response;
            var a = document.createElement('a');
            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = '电桩列表' + new Date().getTime();
          }
          a.click();
          window.URL.revokeObjectURL(url);
        };

        this.exportParams = {
          keyWord: this.keyWord,
          userId: this.operatorId,
        };

        console.log(JSON.stringify(this.exportParams), 'this.exportParams');
        xhr.send(JSON.stringify(this.exportParams));
      });
    },

    selProvince(val) {
      let param = {
        pid: val,
      };
      getCityList(param).then((res) => {
        this.cityList = [...res.data];
        this.city = '';
      });
    },
    getProvinceList() {
      getProvinceList().then((res) => {
        this.provinceList = [...res.data];
      });
    },
    getAgentList() {
      getOperatorSelect().then((res) => {
        this.agentData = [...res.data];
      });
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        getProvinceList().then((res) => {
          return resolve(res.data);
        });
      } else if (node.level === 1) {
        let param = {
          pid: node.data.id,
        };
        getCityList(param).then((res) => {
          resolve(res.data);
        });
      } else if (node.level === 2) {
        let param = {
          regionCode: node.data.id,
        };
        getPoltListV2(param).then((res) => {
          res.data.forEach((element) => {
            element.leaf = false;
          });
          resolve(res.data);
        });
      }
    },
    getDataScopeList() {
      getDataScopeList().then((res) => {
        if (res.data && res.data.length > 0) {
          this.options = [...res.data];
        }
      });
    },
    searchData() {
      this.page = 1;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      let param = {
        keyWord: this.keyWord,
        userId: this.operatorId,
        deviceType: 4,
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      getChargingPileList(param)
        .then((res) => {
          if (res.data && res.data.records.length >= 0) {
            this.tableData = [...res.data.records];
            this.total = res.data.total;
          }

          this.loading = false;
        })
        .catch((e) => {
          console.log('🚀 ~ file: pileList.vue:626 ~ e:', e);
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getDataList();
    },
    addHandler() {
      this.isAdd = true;
      this.formData.id = null;
      this.formData.pileCode = '';
      this.formData.pileName = '';
      this.formData.plot = '';

      this.formData.portCount = '';
      this.formData.simCard = '';
      this.formData.simDueTime = '';
      this.formData.regionCode = '';
      this.formData.pileStatus = '';
      this.formData.electricity = '';
      this.formData.voltage = '';
      this.formData.maxPower = '';
      this.formData.pileType = '';

      this.province = '';
      this.city = '';
      this.area = '';
      this.polt = '';
      this.formData.portCount = '';
      this.popUpModalTitle = '新增四轮车电桩';
      this.dialogVisible = true;
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
    },
    changeallPort() {
      this.allPortFormData.code = null;
      this.allPortFormData.type = '';
      this.allPortVisible = true;
    },
    allPortConfimHandler() {
      this.$refs.form_allPort.validate((valid) => {
        if (valid) {
          allPort(this.allPortFormData).then((res) => {
            if (
              res.message.code == -1 ||
              res.message.code == -2 ||
              res.message.code == -3
            ) {
              this.$message.error(res.message.message);
            } else {
              this.$message({
                message: '操作成功',
                type: 'success',
              });
              this.allPortVisible = false;
              this.getDataList();
            }
          });
        }
      });
    },
    ruleConfimHandler() {
      this.$refs.form_rule.validate((valid) => {
        if (valid) {
          setPileRule(this.ruleFormData).then((res) => {
            if (res.message.code == 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
              });
              this.getDataList();
            } else {
              this.$message.error(res.message.message);
            }
            this.ruleDialogVisible = false;
          });
        }
      });
    },
    confimHandler() {
      if (!this.formData.plot && !this.formData.plotName) {
        this.$message.error('请选择站点或者输入自定义站点');
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.addCard();
          } else {
            this.editCard();
          }
        }
      });
    },
    initSelect(province, city, region) {
      var that = this;
      let param = {
        pid: province,
      };
      getCityList(param).then((res) => {
        this.cityList = [...res.data];
        let param = {
          pid: city,
        };
        getCityList(param).then((res) => {
          this.areaList = [...res.data];
          let param = {
            regionCode: region,
          };
          getPoltListV2(param).then((res) => {
            this.poltList = [...res.data];
            this.poltList.push({ id: -1, plotName: '自定义输入站点' });
          });
        });
      });
    },
    ruleEditHandler(row) {
      this.getRules(row.userId);
      this.ruleFormData.wxRuleId = row.wxRuleId;
      this.ruleFormData.cardRuleId = row.cardRuleId;
      this.ruleFormData.pileId = row.id;
      this.ruleDialogVisible = true;
    },
    qrIssuedHandler(row) {
      let param = {
        id: row.id,
      };
      setQrIssued(param).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: '操作成功',
            type: 'success',
          });
        } else {
          this.$message({
            message: res.message.message,
            type: 'error',
          });
        }
      });
    },
    editCardHandler(row) {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
        this.$refs.form.resetFields();
      }
      this.initSelect(row.provinceCode, row.cityCode, row.regionCode);
      this.isAdd = false;
      this.formData.id = row.id;
      this.formData.pileCode = row.pileCode;
      this.formData.pileName = row.pileName;
      this.formData.plot = row.plot;
      this.formData.portCount = row.portCount;
      this.formData.simCard = row.simCard;
      this.formData.simDueTime = row.simDueTime;
      this.formData.userId = row.userId;
      this.formData.regionCode = row.regionCode;
      this.formData.runningStatus = row.runningStatus;
      this.formData.cpFeeType = row.cpFeeType;
      this.formData.pileStatus = row.pileStatus + '';
      this.formData.deviceType = row.deviceType + '';
      this.formData.pileType = row.pileType + '';
      this.formData.electricity = row.electricity;
      this.formData.voltage = row.voltage;
      this.formData.maxPower = row.maxPower;

      this.province = row.province;

      this.city = row.city;
      this.area = row.regionName;
      this.polt = row.plot;
      this.dialogVisible = true;
      this.popUpModalTitle = '编辑四轮车电桩';
    },
    editCard() {
      this.isDisable = true;
      if (this.formData.plotName) {
        this.formData.plot = null;
      }
      editPile(this.formData)
        .then((res) => {
          if (res.message.code == 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
            });
            this.dialogVisible = false;
            this.getDataList();
          } else {
            this.$message.error(res.message.message);
          }
          this.isDisable = false;
        })
        .catch((e) => {
          this.isDisable = false;
        });
    },
    addCard() {
      this.isDisable = true;
      if (this.formData.plotName) {
        this.formData.plot = null;
      }
      addPile(this.formData)
        .then((res) => {
          if (res.message.code == -1) {
            this.$message.error(res.message.message);
          } else {
            this.$message({
              message: '操作成功',
              type: 'success',
            });
            this.dialogVisible = false;
            this.getDataList();
          }
          this.isDisable = false;
        })
        .catch((e) => {
          this.isDisable = false;
        });
    },
    port(row) {
      this.$router.push({
        path: 'portList',
        query: {
          pileId: row.id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// 去除input Number的箭头
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type='number'] {
  -moz-appearance: textfield;
}

.title {
  display: flex;
  flex-direction: row;

  .import-btn,
  .switch-btn {
    img {
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }
  }
}

.tree {
  position: absolute;
  z-index: 10;
  top: 40px;
  width: 300px;
}

// .is-disabled .el-input__inner {
//   background: #22425b !important;
//   border-color: #22425b !important;
// }

// .el-button--primary {
//   background-color: #02529D;
//   border: none;
// }
</style>
