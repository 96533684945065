<template>
    <div class="main">
        <div class="content-box">
            <div class="title">
                <el-form ref="serachForm" :model="searchForm" label-suffix=":" label-width="120px">
                    <el-row :gutter="20" type="flex" style="flex-wrap:wrap;">
                        <el-col :span="8">
                            <el-form-item label="线路名称">
                                <el-select v-model="searchForm.lineName" filterable placeholder="请输入"
                                    style="width: 100%;" clearable>
                                    <el-option v-for="(item, index) in searchAmmeterNoList" :key="index"
                                        :label="item.lineName" :value="item.lineName">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="运营商名称" v-if="isOperator != 1">
                                <el-select v-model="searchForm.operatorId" filterable placeholder="请选择"
                                    style="width: 100%;" clearable>
                                    <el-option v-for="(item, index) in operatorList" :key="index" :label="item.realName"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <div style="float: right">
                                <el-button @click="searchData" type="primary" style="margin-left:20px;"
                                    icon="el-icon-search">查询</el-button>
                                <el-button @click="resetData" plain style="margin-left:20px;"
                                    icon="el-icon-refresh">重置</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div style="overflow: hidden">
                <el-button type="primary" icon="el-icon-plus" @click="handleAddPowerData"
                    v-show="isOperator != 1">新增</el-button>
            </div>
        </div>
        <div class="content-box">
            <el-table v-loading="loading" border :data="tableData" style="width: 100%">
                <el-table-column type="index" label="序号" align="center" width="60"></el-table-column>
                <el-table-column prop="lineName" label="线路名称" align="center" min-width="130"
                    show-overflow-tooltip></el-table-column>
                <el-table-column prop="currentCeilStr" label="电流上限（A）" align="center" min-width="100"
                    show-overflow-tooltip></el-table-column>
                <!-- <el-table-column prop="powerCeilStr" label="功率上限（kW）" align="center" min-width="120"></el-table-column> -->
                <el-table-column prop="ammeterQty" label="电表数量" align="center" min-width="100"></el-table-column>
                <el-table-column prop="connectorDcQty" label="直流枪数" align="center" min-width="100"></el-table-column>
                <el-table-column prop="connectorAcQty" align="center" label="交流枪数" min-width="130"></el-table-column>
                <el-table-column prop="userName" label="运营商名称" align="center" min-width="100"
                    v-if="isOperator != 1"></el-table-column>
                <el-table-column label="操作" align="center" width="260" fixed="right" v-show="isOperator == 1">
                    <template slot-scope="scope">
                        <div class="editCell">
                            <el-button @click="handlerReadyView(scope.row)">线路视图</el-button>
                            <el-button type="primary" @click="handlerEditAction(scope.row)">编辑</el-button>
                            <el-button type="danger" @click="handlerDelItem(scope.row)">删除</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
                :page-size="listPageSize" layout="total, prev, pager, next,sizes, jumper"
                :total="total"></el-pagination>

        </div>

        <!-- 线路视图  -->
        <el-dialog id="popupLineViewMoal" title="线路视图" :visible.sync="dialogVisibleLine" width="1050px"
            custom-class="no-scrollbar-dialog" @close="handleDialogClose">
            <div>
                <el-button type="primary" @click="addLineNode">添加节点</el-button>
            </div>
            <div style="margin-top: 12px;display: flex;border-radius: 4px; border: 1px solid #E5E6EB;">
                <div
                    style="width:460px;height: 500px; padding: 16px; overflow-y: scroll; border-right: 1px solid #E5E6EB;">
                    <el-tree :data="nodeTreeDataList" ref="viewTree" node-key="id" default-expand-all
                        :expand-on-click-node="false" :render-content="renderContent" :highlight-current="true"
                        :props="defaultProps" @node-click="handleNodeClick">
                    </el-tree>
                </div>
                <div style="width: 550px;padding: 16px;">
                    <div style="margin-bottom: 16px; color: #1D2129;font-size: 16px;font-weight: 500;">编辑节点</div>
                    <!-- 电表编辑 -->
                    <div v-if="isShowEditFlag == 1">
                        <el-form :model="editRightAmmeterForm" :rules="editRightAmmeterFormRules" label-position="top"
                            ref="editRightAmmeterRef" label-width="100px" class="demo-ruleForm"
                            :disabled="isPowerStation">
                            <el-form-item label="" prop="nodeName">
                                <div> <span style="color: #F56C6C;">*</span> 上级节点</div>
                                <el-select-tree v-model="editRightAmmeterForm.nodeName" :data="filterTreeList"
                                    default-expand-all style="width: 100%;" :props="{
                                        value: 'id',
                                        label: (data) => data.nodeName,
                                        children: 'children',
                                    }" check-strictly></el-select-tree>
                            </el-form-item>
                            <el-form-item label="节点电表" prop="ammeterTableData">
                                <el-table :data="editRightAmmeterForm.ammeterTableData" height="auto" border
                                    style="width: 100%" :header-cell-style="{ background: '#F7F8FA' }">
                                    <el-table-column prop="nodeName" label="电表名称" min-width="170">
                                        <template slot-scope="scope">
                                            <el-form-item :rules="[{ required: true, message: '请选择电表名称' }]">
                                                <el-select v-model="editRightAmmeterForm.ammeterName" placeholder="请选择" @change="handleAmmeterChange(scope.row, editRightAmmeterForm.ammeterName)" style="width: 100%;" clearable>
                                                    <el-option v-for="(item,
                                                        index) in editRightAmmeterForm.ammeterSelData" :key="index"
                                                        :label="item.ammeterName" :value="item.id"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="nodeCode" label="电表编号" width="180">
                                    </el-table-column>
                                    <el-table-column prop="currentCeil" label="节点电流上限(A)" width="140">
                                        <template slot-scope="scope">
                                            <el-form-item :rules="editRightAmmeterFormRules.currentCeilStr">
                                                <el-input v-model="scope.row.currentCeilStr"></el-input>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-form-item>
                            <div style="margin-top: 24px;">
                                <span class="dialog-footer">
                                    <el-button type="primary" @click="handleElectricityMeterEdit">保 存</el-button>
                                    <el-button @click="handleResetAmmeterEidt">重 置</el-button>
                                </span>
                            </div>
                        </el-form>
                    </div>
                    <!-- 电桩编辑 -->
                    <div v-else>
                        <el-form :model="editRightPileForm" :rules="editRightPileFormRules" label-position="top"
                            ref="editRightPileRef" label-width="100px" class="demo-ruleForm" :disabled="isPowerStation">
                            <el-form-item label="">
                                <div> <span style="color: #F56C6C;">*</span> 上级节点</div>
                                <el-select-tree v-model="editRightPileForm.nodeName" :data="filterTreeList"
                                    default-expand-all node-key="id" style="width: 100%;" :props="{
                                        value: 'id',
                                        label: (data) => data.nodeName,
                                        children: 'children',
                                    }" check-strictly>
                                </el-select-tree>
                            </el-form-item>
                            <el-form-item label="节点充电桩" prop="pileTableData">
                                <el-table :data="editRightPileForm.pileTableData" height="auto" border
                                    style="width: 100%" :header-cell-style="{ background: '#F7F8FA' }">
                                    <el-table-column prop="nodeName" label="充电桩名称" width="170">
                                        <template slot-scope="scope">
                                            <el-form-item>
                                                <el-select v-model="editRightPileForm.pileName" placeholder="请选择" @change="handlePileChange(scope.row, editRightPileForm.pileName)"
                                                    style="width: 100%;" clearable>
                                                    <el-option v-for="(item, index) in editRightPileForm.PileSelData"
                                                        :key="index" :label="item.pileName"
                                                        :value="item.id"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="pileType" label="充电桩类型" width="120"></el-table-column>
                                    <el-table-column prop="connectorQty" label="充电枪数">
                                        <!-- <template slot-scope="{ row }">
                                            <div>
                                                {{ row.connectorQty }}
                                            </div>
                                        </template> -->
                                    </el-table-column>
                                    <el-table-column prop="pileLimit" label="关联相线">
                                        <template slot-scope="scope">
                                            <el-select v-model="scope.row.pileLimit" placeholder="请选择"
                                                style="width: 100%;" clearable>
                                                <el-option v-for="(item, index) in phaseOption" :key="index"
                                                    :label="item.label" :value="item.value"></el-option>
                                            </el-select>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-form-item>
                            <div style="margin-top: 24px;">
                                <span class="dialog-footer">
                                    <el-button type="primary" @click="handlePileEdit(formDataAddRef)">保 存</el-button>
                                    <el-button @click="handleResetPileEidt">重 置</el-button>
                                </span>
                            </div>
                        </el-form>
                    </div>
                </div>
            </div>
        </el-dialog>

        <!-- 添加节点 -->
        <el-dialog id="popupMoal" title="添加节点" :visible.sync="dialogVisibleLineAdd" width="620px"
            @close="handleDialogClose">
            <div>
                <el-form :model="addNodeForm" :rules="addNodeFormRules" label-position="top" ref="nodeRuleForm"
                    label-width="100px" class="addNodeForm demo-ruleForm">
                    <el-form-item label="上级节点" prop="addDefaultCurrentNode">
                        <el-select-tree v-model="addNodeForm.addDefaultCurrentNode" :data="nodeTreeFilterDataList"
                            @node-click="handleFilterNodeClick" :render-content="renderContent" default-expand-all
                            node-key="id" style="width: 100%;" :props="{
                                value: 'id',
                                label: (data) => data.nodeName,
                                children: 'children',
                            }" check-strictly></el-select-tree>
                        <!-- <el-select v-model="addNodeForm.addDefaultCurrentNode" :popper-append-to-body="false"
                            style="width:100%;">
                            <el-option value="queryUserName" style="height: auto;overflow: auto;">
                                <el-tree :data="nodeTreeFilterDataList" node-key="id" default-expand-all
                                    :expand-on-click-node="false" :render-content="renderContent"
                                    @node-click="handleFilterNodeClick">
                                </el-tree>
                            </el-option>
                        </el-select> -->
                    </el-form-item>
                    <el-form-item label="节点类型" prop="nodeType">
                        <el-radio-group v-model="addNodeForm.nodeType" @change="changeNodeType">
                            <el-radio :label="1">电表</el-radio>
                            <el-radio :label="2">充电桩</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <!-- 电表添加 -->
                    <div v-show="isShowAmmeterModel == 1">
                        <el-form-item label="节点电表" prop="ammeterTableData">
                            <template>
                                <div style="display: flex;justify-content: space-between; margin-bottom: 10px;">
                                    <el-select v-model="addNodeForm.ammeterName" placeholder="请选择" style="width: 90%;"
                                        filterable multiple clearable>
                                        <el-option v-for="(item, index) in addNodeForm.ammeterSelArray" :key="index"
                                            :label="item.ammeterName" :value="item.id"></el-option>
                                    </el-select>
                                    <div style="display: flex;justify-content: center;"> <el-button type="primary"
                                            @click="handleAddNodeTable">添加</el-button> </div>
                                </div>
                            </template>

                            <el-table :data="addNodeForm.ammeterTableData" height="250" border style="width: 100%"
                                :header-cell-style="{ background: '#F7F8FA' }">
                                <el-table-column prop="nodeName" label="电表名称" width="180">
                                    <!-- <template slot-scope="{row}">
                                        <el-select v-model="row.ammeterName" placeholder="请选择" style="width: 100%;" @change="addHandleSelAmmeterItem"
                                            clearable>
                                            <el-option v-for="(item, index) in addNodeForm.ammeterSelArray" :key="index"
                                                :label="item.ammeterName" :value="item.id"></el-option>
                                        </el-select>
                                    </template> -->
                                </el-table-column>
                                <el-table-column prop="nodeCode" label="电表编号" width="140"
                                    show-overflow-tooltip></el-table-column>
                                <el-table-column prop="currentCeil" label="" align="center">
                                    <template v-slot:header="{ column, $index }">
                                        <span class="required-label"> <span style="color: #F56C6C;">*</span>
                                            节点电流上限(A)</span>
                                    </template>
                                    <template slot-scope="{row, $index}">
                                        <el-form-item :prop="'ammeterTableData[' + $index + '].currentCeil'"
                                            :rules="[{ required: true, message: '节点电流上限不能为空', trigger: 'blur' }]">
                                            <el-input v-model.number="row.currentCeil"
                                                @input="validateInput(row.currentCeil)" align="center"
                                                clearable></el-input>
                                        </el-form-item>

                                        <!-- <el-form-item v-for="(domain, index) in addNodeForm.ammeterTableData" :key="domain.refId"
                                            :prop="'ammeterTableData.' + index + '.currentCeil'" :rules="{
                                                required: true, message: '节点电流上限不能为空', trigger: 'blur'}">
                                            <el-input v-model="domain.currentCeil"></el-input>
                                        </el-form-item> -->

                                    </template>
                                </el-table-column>

                                <el-table-column label="操作">
                                    <template slot-scope="scope">
                                        <span @click="handlerAddDelItem(scope.$index)"
                                            style="color: #86909C;font-size: 14px;cursor: pointer;">移除</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- <div style="display: flex;justify-content: center;">
                                <div style="cursor: pointer;" @click="handleAddNodeTable"> + 添加节点电表</div>
                            </div> -->
                        </el-form-item>
                    </div>
                    <!-- 充电桩添加 -->
                    <div v-show="isShowAmmeterModel == 2">
                        <el-form-item label="节点充电桩" prop="pileTableData">
                            <template>
                                <div style="display: flex;justify-content: space-between; margin-bottom: 10px;">
                                    <el-select v-model="addNodeForm.pileName" ref="selectPileRef" placeholder="请选择"
                                        filterable style="width: 90%;" multiple clearable>
                                        <el-option v-for="(item, index) in addNodeForm.pileSelArray" :key="index"
                                            :label="item.pileName" :value="item.id"></el-option>
                                    </el-select>
                                    <div style="display: flex;justify-content: center;"> <el-button type="primary"
                                            @click="handleAddNodeTable">添加</el-button> </div>
                                </div>
                            </template>
                            <el-table :data="addNodeForm.pileTableData" height="250" border style="width: 100%"
                                :header-cell-style="{ background: '#F7F8FA' }">
                                <el-table-column prop="nodeName" label="充电桩名称" width="160" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column prop="pileTypeVal" label="电桩类型" width="100">
                                </el-table-column>
                                <el-table-column prop="connectorQty" label="充电枪数" width="80">
                                </el-table-column>
                                <el-table-column prop="label" label="关联相线" width="120">
                                    <template slot-scope="{row}">
                                        <el-form-item>
                                            <el-select v-model="row.refPhase" placeholder="请选择" style="width:100px;">
                                                <el-option v-for="(item_w, index_w) in wireMethodOption" :key="index_w"
                                                    :label="item_w.refPhase" :value="item_w.value"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作">
                                    <template slot-scope="scope">
                                        <span @click="handlerAddDelItem(scope.$index)"
                                            style="color: #86909C;font-size: 14px;cursor: pointer;">移除</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- <div style="display: flex;justify-content: center;">
                                <div> + 添加节点电桩</div>
                            </div> -->
                        </el-form-item>
                    </div>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleLineAdd = false">取 消</el-button>
                <el-button type="primary" @click="confimHandlerAdd(formDataAddRef)" :disabled="firstAction">保 存</el-button>
            </span>
        </el-dialog>

        <!-- 新增 && 编辑  -->
        <el-dialog id="popupMoal" :title="addDialogTitle" :visible.sync="dialogVisibleAdd" width="650px"
            @close="handleDialogAddClose">
            <el-form ref="formDataAddRef" :model="formDataAdd" label-width="150px" :rules="formDataAddRule">
                <div>
                    <div>
                        <el-form-item class="nav-form-item" label="线路名称" prop="lineName">
                            <el-input maxlength="30" v-model="formDataAdd.lineName" style="width:240px;"
                                placeholder="请输入" show-word-limit clearable />
                        </el-form-item>
                        <el-form-item label="线路站点" prop="plotId">
                            <el-select v-model="formDataAdd.plotId" placeholder="请选择，创建后不可变更" style="width:240px;"
                                :disabled="addDialogTitle == '编辑线路' ? true : false">
                                <el-option v-for="(item, index) in plotList" :key="index" :label="item.plotName"
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item class="nav-form-item" label="线路电流上限">
                            <el-input-number size="small" :min="1" v-model="currentCeilVal">
                            </el-input-number>
                            <span style="margin-left: 10px;">A</span>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleAdd = false">取 消</el-button>
                <el-button type="primary" @click="confimHandler(formDataAddRef)">保 存</el-button>
            </span>
        </el-dialog>

        <!-- 删除列表 -->
        <el-dialog :title="'删除：' + delItamLineName" :visible.sync="dialogVisibleDelLine" width="30%">
            <div style="margin-bottom: 20px;font-size: 16px;">确定删除该线路?</div>
            <div style="line-height: 20px;font-weight: bold;font-size: 14px;color:#F56C6C;">
                删除线路后，该线路将不再进行功率调控，存在超限的风险，请谨慎操作（该操作不可撤回）</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleDelLine = false">取 消</el-button>
                <el-button type="primary" @click="handeSureDelListLine">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 删除线路节点 -->
        <el-dialog :title="'删除：' + delItamPowerName.name" :visible.sync="dialogVisibleDelNode" width="30%">
            <div style="margin-bottom: 20px;font-size: 16px;">确定删除该{{ delItamPowerName.type == 2 ? '电表' : '电桩' }}?</div>
            <div style="line-height: 20px;font-weight: bold;font-size: 14px;color:#F56C6C;"
                v-if="delItamPowerName.type == 2">
                删除电表后，线路层级中的电表及其下的支点将一并从{{ delItamPowerName.name }}中移除
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleDelNode = false">取 消</el-button>
                <el-button type="primary" @click="handleSureDelNode">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {
    getLineAllList,
    getOperatorList,
    getPlotList,
    getAddLine,
    getSearchLine,
    getAmmeterList,
    getNodeTreeList,
    delLineNodeTree,
    delLineTableList,
    getChargingPileList,
    updateItemLine,
    addNodeTree,
    updateLineNodeTree,
} from "@/api/line";
import { cloneDeep } from "lodash-es";
import { getName } from '@/utils/auth.js'
import delNodeIcon from '@/assets/delete-outlined.png'
import actionDel from '@/assets/action-del.png'

// 编辑节点数据过滤
const filterTreeByNodeType = (tree, nodeTypes, excludes = []) => {
    let t = cloneDeep(tree);
    return t.reduce((acc, node) => {
        if (nodeTypes.includes(node.nodeType) && !excludes.includes(node.id)) {
            node.children = filterTreeByNodeType(node.children, nodeTypes, excludes);
            acc.push(node);
        }
        return acc;
    }, []);
};

export default {
    data() {
        var validateSpace = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('线路名称不能为空'));
            } else if (value.trim() === '') {
                callback(new Error('线路名称不能为空'));
            } else {
                callback();
            }
        };
        return {
            loading: false,
            isPowerStation: false,
            isOperator: null,
            showDelImg: 2,
            nodeIdDel: null,
            currentCeilVal: undefined,
            searchForm: {
                id: '',
                lineName: '',
                operatorId: '',
                pageNo: 1,
                pageSize: 10,
            },
            formDataAdd: {
                lineName: '',
                plotId: '',
                currentCeil: undefined,
            },
            formDataAddRule: {
                lineName: [
                    { required: true, message: "请输入线路名称", trigger: "blur" },
                    { validator: validateSpace, trigger: 'blur' },
                ],
                plotId: [
                    { required: true, message: "请选择站点名称", trigger: "blur" },
                ],
                currentCeil: [
                    { required: true, message: "请选择站点名称", trigger: "blur" },
                ]
            },
            wireMethodOption: [{
                value: 'A',
                refPhase: 'A相'
            }, {
                value: 'B',
                refPhase: 'B相'
            }, {
                value: 'C',
                refPhase: 'C相'
            }],
            phaseOption: [
                {
                    value: "A",
                    label: "A相",
                },
                {
                    value: "B",
                    label: "B相",
                },
                {
                    value: "C",
                    label: "C相",
                },
            ],
            tableData: [],
            searchAmmeterNoList: [],
            operatorList: [],
            plotIdTableData: [],
            plotList: [],
            dialogVisibleDelLine: false,
            delItamLineName: '',
            delItamLineNameId: '',
            delItamPowerName: {
                name: '',
                type: null,
                delId: ''
            },
            addDialogTitle: '',
            total: 0,
            listPageSize: 20,
            pageSize: 10,
            pageNo: 1,
            page: 1,
            formDataAddRef: null,
            dialogVisibleAdd: false,
            dialogVisibleLog: false,
            dialogVisibleDelNode: false,
            dialogVisibleLine: false,
            dialogVisibleLineAdd: false,
            actionListLineId: '', //列表点击获取线路id
            nodeTreeDataList: [], //树形数据
            nodeTreeFilterDataList: [], //添加节点过滤电桩树形数据
            /**
             * 编辑定义 线路视图编辑-电表
             */

            editRightAmmeterForm: {
                ammeterName: '',
                nodeName: '',
                pileName: '',
                refId: '',
                ammeterSeCurrentNode: [],
                ammeterSelData: [],
                ammeterTableData: [
                    {
                        nodeName: '',
                        nodecode: '',
                        currentCeilStr: ''
                    }
                ],
            },
            editRightAmmeterFormRules: {
                nodeName: [
                    { required: true, message: "请选择", trigger: "blur" },
                ],
                ammeterNodeName: [
                    { required: true, message: "请选择", trigger: "blur" },
                ],
                ammeterParentNode: [
                    { required: true, message: "请输入", trigger: "blur" },
                ],
                ammeterTableData: [
                    { required: true, message: "请输入", trigger: "blur" },
                ],
                currentCeilStr: [
                    { required: true, message: "请输入", trigger: "blur" },
                ]
            },
            //线路视图编辑-电桩
            editRightPileForm: {
                nodeName: '',
                pileName: '',
                pileParentNode: '',
                PileSelData: [],
                pileTableData: [
                    {
                        pileType: '',
                        pileName: '',
                        pileNo: '',
                        pileNum: '',
                        pileLimit: ''
                    }
                ],
            },
            editRightPileFormRules: {
                pileNodeName: [
                    { required: true, message: "请选择", trigger: "blur" },
                ],
                nodeName: [
                    { required: true, message: "请输入线路名称", trigger: "blur" },
                ],
                pileParentNode: [
                    { required: true, message: "请输入线路名称", trigger: "blur" },
                ],
                pileTableData: [
                    { required: true, message: "请输入线路名称", trigger: "blur" },
                ]
            },
            //线路视图选择
            isShowEditFlag: 1,
            /**
             * 添加节点定义
             */
            addCurrentNode: [],
            isShowAmmeterModel: 1,
            addNodeForm: {
                addDefaultCurrentNode: '', //获取当前作为上级节点
                plotId: '', //站点ID
                parentId: '', //上级ID(就是当前节点ID)
                lineId: '', //线路ID
                parentNode: '',
                nodeType: 1,
                label: '', //关联相线
                ammeterTableData: [],
                ammeterSelArray: [],
                pileTableData: [], //节点充电桩
            },
            addNodeFormRules: {
                ammeterTableData: [
                    { required: true, message: "请输入上级节点", trigger: "blur" },
                ],
                addDefaultCurrentNode: [
                    { required: true, message: "请输入上级节点", trigger: "blur" },
                ],
                parentNode: [
                    { required: true, message: "请输入线路名称", trigger: "blur" },
                ],
                nodeType: [
                    { required: true, message: "请输入选择节点类型", trigger: "blur" },
                ],
                currentCeil: [
                    { required: true, message: "请输入线路名称", trigger: "blur" },
                ],
                pileTableData: [
                    { required: true, message: "请输入线路名称", trigger: "blur" },
                ],
            },
            currentItem: {},
            filterTreeList: [],
            defaultProps: {
                children: 'children',
                label: 'id',
                isLeaf: (data, node) => {
                    if (node.level === 2) {
                        return true
                    }
                }
            },
            firstAction: false
        };
    },
    created() {
        this.getLineAllListFn();
        this.getOperatorListFn();
        this.getPlotListFn();
    },
    mounted() {
        //isOperator为0代表不可操作，1可操作属于运营人员
        let getIsoperator = JSON.parse(getName())
        this.isOperator = getIsoperator.userInfo.isOperator
    },
    watch: {
        // 当 inputValue 改变时，清除错误消息（如果需要）
        inputValue(newVal) {
            if (newVal >= 1) {
                this.$message('输入值必须大于0')
            }
        },
    },
    methods: {
        getLineAllListFn() { //获取电表列表数据
            this.loading = true;
            getSearchLine({
                lineName: '',
                operatorId: '',
                pageNo: this.pageNo,
                pageSize: this.listPageSize,
            }).then((res) => {
                if (res.message.status == 200) {
                    if (res.data.records.length > 0) {
                        this.total = res.data.total;
                        this.tableData = [...res.data.records];
                        this.searchAmmeterNoList = this.tableData.filter(item => item.lineName)
                    }

                }
                this.loading = false;
            });
        },
        getOperatorListFn() { //获取运营商
            let param = {
                keyWord: this.searchForm.operatorName,
                pageNo: 0,
                pageSize: 10,
            }
            getOperatorList(param).then((res) => {
                if (res.data && res.data.records.length > 0) {
                    this.operatorList = res.data.records;
                }
            });
        },
        getPlotListFn() { //获取站点
            let param = {
                keyWord: '',
                pageNo: this.page,
                pageSize: 500,
            };
            getPlotList(param).then((res) => {
                if (res.data && res.data.records.length >= 0) {
                    this.plotList = res.data.records
                    this.plotList = this.plotList.filter(item => item.id = parseInt(item.id))
                }
            });
        },
        confimHandler() { //新增 & 编辑
            this.$refs.formDataAddRef.validate((valid) => {
                if (valid) {
                    if (this.addDialogTitle == '编辑线路') {//编辑
                        this.formDataAdd.currentCeil = this.currentCeilVal === undefined ? '' : this.currentCeilVal
                        updateItemLine(this.formDataAdd).then((res) => {
                            if (res.message.status == 200) {
                                this.$message({
                                    message: '保存成功！',
                                    type: 'success'
                                });
                                this.dialogVisibleAdd = false
                                this.getLineAllListFn();
                            } else {
                                this.$message(res.message.message);
                            }
                        })
                    } else { //新增
                        delete this.formDataAdd.id;
                        this.formDataAdd.currentCeil = this.currentCeilVal === undefined ? '' : this.currentCeilVal
                        console.log(this.formDataAdd);
                        getAddLine(this.formDataAdd).then((res) => {
                            if (res.message.status == 200) {
                                this.$message({
                                    message: '保存成功！',
                                    type: 'success'
                                });
                                this.dialogVisibleAdd = false
                                this.getLineAllListFn();
                                //新增成功显示弹框
                                this.actionListLineId = res.data
                                this.getNodeTreeListFn(res.data);
                                this.getNodeFilterTreeListFn(res.data);
                                this.dialogVisibleLine = true
                            } else {
                                this.$message(res.message.message);
                            }
                        }).catch(err => {
                            this.$message(err)
                        })
                    }
                }
            })
        },
        getSearchLineFn() {
            getSearchLine(this.searchForm).then((res) => {
                if (res.data && res.data.records.length >= 0) {
                    this.tableData = res.data.records;
                }
            })
        },
        searchData() { //查询
            this.getSearchLineFn();
        },
        resetData() { //重置
            this.searchForm.lineName = ''
            this.searchForm.operatorId = ''
            this.pageSize = 10
            this.pageNo = 1;
            this.getLineAllListFn();
        },
        handleDialogClose() {
            this.isThreadsStop = false
        },
        handleSizeChange(val) {
            this.listPageSize = val;
            this.getLineAllListFn();
        },
        handleCurrentChange(val) {
            this.pageNo = val;
            this.page = val;
            this.getLineAllListFn();
        },
        handleAddPowerData() { //新增线路
            this.formDataAdd.lineName = ''
            this.formDataAdd.plotId = ''
            this.formDataAdd.currentCeil = ''
            this.addDialogTitle = '新增线路'
            this.dialogVisibleAdd = true
        },
        handleDialogAddClose() {//清空校验
            this.$refs.formDataAddRef.clearValidate();
        },
        handlerReadyView(row) {
            console.log(row, 77999)
            // 先清空数据
            this.editRightAmmeterForm.nodeName = ''
            this.filterTreeList = []
            this.editRightAmmeterForm.ammeterTableData = []
            this.editRightAmmeterForm.ammeterName = ''

            this.formDataAdd = Object.assign({}, row);
            this.dialogVisibleLine = true
            this.actionListLineId = row.id
            this.getNodeTreeListFn(row.id);
            this.getNodeFilterTreeListFn(row.id);
        },
        handlerEditAction(row) {
            console.log(row)
            this.formDataAdd = Object.assign({}, row);
            this.currentCeilVal = (row.currentCeil == 0 || row.currentCeil == '') ? undefined : row.currentCeil
            console.log(this.currentCeilVal, 467974);
            this.formDataAdd.plotId = row.plotId
            this.addDialogTitle = '编辑线路'
            this.dialogVisibleAdd = true
        },
        handlerDelItem(row) { //删除列表
            console.log(row, 666333)
            this.delItamLineNameId = row.id
            this.delItamLineName = row.lineName
            this.dialogVisibleDelLine = true
        },
        handeSureDelListLine() {
            delLineTableList({ id: this.delItamLineNameId }).then((res) => {
                if (res.message.code == 0) {
                    this.$message({
                        message: "删除成功",
                        type: "success",
                    });
                    this.dialogVisibleDelLine = false
                    this.getLineAllListFn();
                } else {
                    this.$message.error(res.data);
                }
            });
        },
        /**
         * 
         * 线路视图所有方法
         * 
         */
        getNodeTreeListFn(lineId) { // 获取树形数据
            getNodeTreeList({ lineId }).then((res) => {
                if (res.data && res.data.length >= 0) {
                    const changeData = JSON.parse(JSON.stringify(res.data))
                    this.nodeTreeDataList = this.filterTree(changeData, node => node.nodeType < 4);
                    //全局公用站点ID
                    this.addNodeForm.plotId = this.nodeTreeDataList[0].plotId
                }
            })
        },
        getNodeFilterTreeListFn(lineId) { // 获取电桩树形数据
            // getNodeTreeList({ lineId, filter: 2 }).then((res) => {
            //     if (res.data && res.data.length >= 0) {
            //         const changeData = JSON.parse(JSON.stringify(res.data))
            //         this.nodeTreeFilterDataList = this.filterTree(changeData, node => node.nodeType < 4);
            //     }
            // })
            getNodeTreeList({ lineId, filter: 2 }).then((res) => {
                if (res.data && res.data.length >= 0) {
                    const changeData = JSON.parse(JSON.stringify(res.data))
                    this.nodeTreeFilterDataList = this.filterTree(changeData, node => node.nodeType < 4);
                }
            })
        },
        filterTree(tree, condition) { //树形处理
            return tree.filter(node => {
                if (condition(node)) {
                    if (node.children) {
                        node.children = this.filterTree(node.children, condition);
                    }
                    return true; // 保留节点
                }
                if (node.children) {
                    const filteredChildren = this.filterTree(node.children, condition);
                    return filteredChildren.length > 0 && (node.children = filteredChildren);
                }
                return false;
            });
        },
        getAmmeterListFn(plotId) { //获取下拉电表数据
            getAmmeterList({ plotId }).then((res) => {
                if (res.data && res.data.length >= 0) {
                    this.editRightAmmeterForm.ammeterSelData = res.data
                }
            });
        },
        getEditAmmeterListFn(plotId, ammeterId) { //获取编辑下拉电表数据
            getAmmeterList({ plotId, ammeterId, isFilter: 1 }).then((res) => {
                if (res.data && res.data.length >= 0) {
                    this.editRightAmmeterForm.ammeterSelData = res.data
                }
            });
        },
        delLineNodeTreeFn(node, data) { //删除节点-线路节点ID
            console.log('删除', node, data)
            this.delItamPowerName.name = data.nodeName
            this.delItamPowerName.type = data.nodeType
            this.delItamPowerName.id = data.id
            this.dialogVisibleDelNode = true
        },
        handleSureDelNode() {
            delLineNodeTree({ id: this.delItamPowerName.id }).then((res) => {
                if (res.message && res.message.status == 200) {
                    this.$message({
                        message: "删除成功",
                        type: "success",
                    });

                    this.dialogVisibleDelNode = false
                    this.nodeTreeDataList = []
                    this.getNodeTreeListFn(this.actionListLineId)
                    //右边编辑为初始化
                    this.nodeTreeDataList = []
                    this.editRightAmmeterForm.ammeterName = ''
                    this.editRightAmmeterForm.ammeterTableData = []
                    this.editRightPileForm.pileName = ''
                    this.editRightPileForm.pileTableData = []
                    this.isShowEditFlag = 1
                    // this.$nextTick(() => {
                    //     this.$refs.viewTree.updateKeyChildren(parent.parent.id, parent);
                    // });
                } else {
                    this.$message(res.messge.messge)
                }
            })
        },
        handleNodeClick(e, parentNode) { //树形操作获取当前节点
            console.log('获取当前节点', e)
            console.log('获取当前父级节点', parentNode.parent.data)
            this.currentItem = e;
            if (e.nodeType == 1) { //电站-不支持新增
                this.addNodeForm.plotId = e.plotId //站点ID
                this.addNodeForm.addDefaultCurrentNode = e.nodeName
                this.addNodeForm.parentId = e.id
                this.addNodeForm.lineId = e.lineId
                this.isShowEditFlag = 1
                //右侧不可编辑和清空校验
                this.isPowerStation = true
                // if(this.$refs.editRightAmmeterRef){
                //     this.$refs.editRightAmmeterRef.clearValidate()
                // }
                //  if(this.$refs.editRightPileRef){
                //      this.$refs.editRightPileRef.clearValidate()
                // }
                // 先清空数据
                this.editRightAmmeterForm.nodeName = ''
                this.filterTreeList = []
                this.editRightAmmeterForm.ammeterTableData = []
                this.editRightAmmeterForm.ammeterName = ''
            } else if (e.nodeType == 2) { //电表-新增节点
                this.isShowEditFlag = 1
                let showActionArray = [
                    {
                        nodeCode: e.nodeCode,
                        nodeName: e.nodeName,
                        currentCeilStr: e.currentCeilStr,
                        plotId: e.plotId
                    },
                ]
                //编辑当前节点
                this.filterTreeList = filterTreeByNodeType(
                    this.nodeTreeDataList,
                    [1, 2],
                    [e.id]
                );
                this.editRightAmmeterForm.ammeterName = e.refId
                this.editRightAmmeterForm.nodeName = parentNode.parent.data.id
                this.editRightAmmeterForm.ammeterSeCurrentNode = showActionArray
                this.editRightAmmeterForm.ammeterTableData = showActionArray
                // this.getAmmeterListFn(e.plotId);
                this.getEditAmmeterListFn(e.plotId, e.refId);
                //添加节点
                this.addNodeForm.addDefaultCurrentNode = e.nodeName
                this.addNodeForm.parentId = e.id
                this.addNodeForm.lineId = e.lineId
                this.isPowerStation = false
            } else if (e.nodeType == 3) { //电桩-新增节点
                this.isShowEditFlag = 2
                let showActionArray = [
                    {
                        nodeCode: e.nodeCode,
                        nodeName: e.nodeName,
                        pileType: e.pileType == 0 ? '交流' : '直流',
                        currentCeilStr: e.currentCeilStr,
                        wireMethod: 999,
                        refId: e.refId,
                        pileLimit: e.refPhase,
                        connectorQty: e.connectorQty
                    },
                ]
                this.filterTreeList = filterTreeByNodeType(
                    this.nodeTreeDataList,
                    [1, 2],
                    [e.id]
                );
                //新增节点
                this.addDefaultCurrentNode = e.nodeName
                this.addCurrentNode = parentNode.parent.data
                //编辑节点

                this.editRightPileForm.nodeName = parentNode.parent.data.id;
                console.log(this.editRightPileForm.nodeName, 7979979797);
                this.editRightPileForm.pileTableData = showActionArray;
                //获取桩数据
                // this.editRightAmmeterForm.pileName = parseInt(e.refId)
                this.getPileListFn(e.plotId, e.refId)
                this.isPowerStation = false

            }
        },
        handleFilterNodeClick(e, parentNode) { //树形操作获取当前节点
            console.log('获取当前过滤节点', e)
            console.log('获取当前过滤父级节点', parentNode.parent.data)
            if (e.nodeType == 1) {
                this.addNodeForm.plotId = e.plotId //站点ID
                this.addNodeForm.addDefaultCurrentNode = e.nodeName
                this.addNodeForm.parentId = e.id
                this.addNodeForm.lineId = e.lineId
            } else if (e.nodeType == 2) { //电表-新增节点
                this.isShowEditFlag = 1
                let showActionArray = [
                    {
                        nodeCode: e.nodeCode,
                        nodeName: e.nodeName,
                        currentCeilStr: e.currentCeilStr,
                        plotId: e.plotId
                    },
                ]
                // 当前节点
                this.editRightAmmeterForm.nodeName = e.nodeName
                this.editRightAmmeterForm.ammeterSeCurrentNode = showActionArray
                this.editRightAmmeterForm.ammeterTableData = showActionArray
                this.getAmmeterListFn(e.plotId);
                //添加节点
                this.addNodeForm.addDefaultCurrentNode = e.nodeName
                this.addNodeForm.parentId = e.id
                this.addNodeForm.lineId = e.lineId
            }
            // 电表下拉数据
            this.getAmmeterListFn(this.addNodeForm.plotId)
            //电桩下拉数据
            this.getFilterPileListFn()
        },

        /**
         * 
         * 添加节点所有方法
         * 
         */
        validateInput(inpuVal) {
            if (inpuVal != '' && inpuVal <= 0) this.$message('请输入正整数且大于0')
        },
        addLineNode() { //左上角添加节点
            if (this.isShowEditFlag == 2) {
                this.$message('充电桩不支持添加节点');
                return
            }
            if (this.addNodeForm.addDefaultCurrentNode == '') { //未选择节点-默认选择电站
                this.addNodeForm.addDefaultCurrentNode = this.nodeTreeDataList[0].nodeName
                this.addNodeForm.plotId = this.nodeTreeDataList[0].plotId //站点ID
                this.addNodeForm.parentId = this.nodeTreeDataList[0].id
                this.addNodeForm.lineId = this.nodeTreeDataList[0].lineId
            }
            //解除防抖
            this.firstAction = false
            this.addNodeForm.nodeType = 1
            //清空上次操作数据
            // this.addNodeForm.ammeterName = ''
            // this.addNodeForm.pileName = ''
            // this.addNodeForm.ammeterSelArray = []
            // this.addNodeForm.pileSelArray = []
            this.addNodeForm.ammeterTableData = []
            this.addNodeForm.pileTableData = []
            //获取添加节点树形数据
            this.getNodeFilterTreeListFn(this.actionListLineId);
            //电表下拉数据
            this.addGetAmmeterListFn()
            //获取过滤电桩数据
            this.getFilterPileListFn()

            this.dialogVisibleLineAdd = true
        },
        addGetAmmeterListFn() { //获取节点电表下拉数据
            let param = {
                plotId: this.formDataAdd.plotId,
                isFilter: 1,
            }
            getAmmeterList(param).then((res) => {
                if (res.data && res.data.length >= 0) {
                    this.addNodeForm.ammeterSelArray = res.data
                }
            });
        },
        handleAddNodeTable() {
            if (this.isShowAmmeterModel == 1) { //节点电表选择到表格
                if (this.addNodeForm.ammeterName.length === 0) {
                    this.$message('请选择电表');
                    return
                }

                console.log(this.addNodeForm.ammeterName, 585858, this.addNodeForm.ammeterSelArray);
                let getMangeData = []
                this.addNodeForm.ammeterSelArray.forEach(item => {
                    this.addNodeForm.ammeterName.forEach(item2 => {
                        if (item.id == item2) {
                            getMangeData.push(item)
                        }
                    })
                })
                console.log('电表转换数据', getMangeData);
                let newDataItem = []
                getMangeData.forEach((item, index) => {
                    newDataItem.push({
                        nodeName: item.ammeterName, //电表名称
                        nodeCode: item.ammeterNo,  //电表编号
                        currentCeil: '', //节点电流上限
                        refId: item.id, //电表名称Id
                    })
                })
                if (this.haveSameIds(this.addNodeForm.pileTableData, newDataItem)) {
                    this.$message('该电桩已存在，请勿重复添加');
                    return
                }

                // const getMangeData = this.addNodeForm.ammeterSelArray.filter(item => item.id == this.addNodeForm.ammeterName)
                // const tableDataItem = {
                //     nodeName: getMangeData[0].ammeterName, //电表名称
                //     nodeCode: getMangeData[0].ammeterNo,  //电表编号
                //     currentCeil: '', //节点电流上限
                //     refId: this.addNodeForm.ammeterName, //电表名称Id
                // }
                // const isCommonId = this.addNodeForm.ammeterTableData.filter(item => item.refId == getMangeData[0].id)
                // if (isCommonId.length > 0) {
                //     this.$message('该电表已存在，请勿重复添加');
                //     return
                // }
                this.addNodeForm.ammeterTableData = newDataItem
                this.addNodeForm.ammeterName = ''
            } else { //节点电桩选择到表格
                if (this.addNodeForm.pileName.length === 0) {
                    this.$message('请选择电桩');
                    return
                }

                console.log(this.addNodeForm.pileName, 585858, this.addNodeForm.pileSelArray);
                let getMangeData = []
                this.addNodeForm.pileSelArray.forEach(item => {
                    this.addNodeForm.pileName.forEach(item2 => {
                        if (item.id == item2) {
                            getMangeData.push(item)
                        }
                    })
                })
                console.log('转换数据', getMangeData);
                let newDataItem = []
                getMangeData.forEach((item, index) => {
                    newDataItem.push({
                        nodeName: item.pileName + '(' + item.pileCode + ')', //电桩名称
                        pileTypeVal: item.pileType == 0 ? '交流' : '直流',  //电桩类型
                        refId: item.id, //电桩名称Id
                        refPhase: '', //	关联相线(无/A/B/C)
                        connectorQty: item.portCount, //枪数
                        nodeCode: item.pileCode
                    })
                })
                if (this.haveSameIds(this.addNodeForm.pileTableData, newDataItem)) {
                    this.$message('该电桩已存在，请勿重复添加');
                    return
                }
                console.log('添加数据', newDataItem);
                this.addNodeForm.pileTableData = newDataItem
                this.addNodeForm.pileName = ''
            }
        },
        haveSameIds(arr1, arr2) {
            const ids1 = new Set(arr1.map(item => item.refId));
            const ids2 = new Set(arr2.map(item => item.id));
            return ids1.size === ids2.size && [...ids1].every(id => ids2.has(id));
        },
        handlerAddDelItem(e) { //删除当前表格某一列
            if (this.isShowAmmeterModel == 1) {
                this.addNodeForm.ammeterTableData.splice(e, 1)
            } else {
                this.addNodeForm.pileTableData.splice(e, 1)
            }
            this.$message({
                message: "删除成功",
                type: "success",
            });
        },
        changeNodeType(e) { //编辑类型切换
            this.isShowAmmeterModel = e
        },
        getPileListFn(sendPlotId, refId) { //获取编辑电桩
            let param = {
                plotId: sendPlotId,
                pileId: refId,
                isFilter: 1,
            };
            getChargingPileList(param).then((res) => {
                if (res.data && res.data.length >= 0) {
                    this.editRightPileForm.PileSelData = res.data
                    this.editRightPileForm.PileSelData = this.editRightPileForm.PileSelData.filter(item => item.id = parseInt(item.id))
                    this.editRightPileForm.pileName = parseInt(refId)
                }
            })
        },
        getFilterPileListFn() { //获取过滤电桩
            let param = {
                isFilter: 1,
                plotId: this.addNodeForm.plotId,
            };
            getChargingPileList(param).then((res) => {
                if (res.data && res.data.length >= 0) {
                    // this.addNodeForm.pileName = ''
                    this.addNodeForm.pileSelArray = res.data
                }
            })
        },
        remove(node, data) {
            const parent = node.parent;
            const children = parent.data.children || parent.data;
            const index = children.findIndex(d => d.id === data.id);
            children.splice(index, 1);
        },
        //保存添加节点
        confimHandlerAdd() {
            console.log('保存新增节点数据:', this.addNodeForm)
            // 电站添加电表
            let param = {}
            if (this.isShowAmmeterModel == 1) { //电表数据
                if (this.addNodeForm.ammeterTableData.length == 0) {
                    this.$message('请添加节点电表');
                    return
                }
                if (this.addNodeForm.ammeterTableData.length.length > 0) {
                    this.$message('请添加节点电流上限');
                    return
                }
                const isFillDataNull = this.addNodeForm.ammeterTableData.filter(item => item.currentCeil === '')
                if (isFillDataNull.length > 0) {
                    this.$message('节点电流上限不能为空');
                    return
                }
                const isFillDataCop = this.addNodeForm.ammeterTableData.filter(item => item.currentCeil <= 0)
                if (isFillDataCop.length > 0) {
                    this.$message('节点电流上限不能小于0');
                    return
                }
                param = {
                    "lineId": this.addNodeForm.lineId,
                    // "nodeInfoList": [{
                    //         "currentCeil": 0, //节点电流
                    //         "nodeName": "",
                    //         "refId": 0, //电表名称ID
                    // }],
                    "nodeInfoList": this.addNodeForm.ammeterTableData,
                    "nodeType": 2,
                    "parentId": this.addNodeForm.parentId,
                    "plotId": this.addNodeForm.plotId
                }
            } else { //电桩数据
                if (this.addNodeForm.pileTableData.length == 0) {
                    this.$message('请添加节点充电桩');
                    return
                }
                this.addNodeForm.pileTableData.forEach(item => {
                    if (item.pileTypeVal == '直流') {
                        item.pileType = 1
                    } else {
                        item.pileType = 0
                    }
                })
                // 处理桩编号
                this.addNodeForm.pileTableData.filter(item => item.nodeName = item.nodeName.replace('(' + item.nodeCode + ')', ''))
                param = {
                    "lineId": this.addNodeForm.lineId,
                    "nodeInfoList": this.addNodeForm.pileTableData,
                    "nodeType": 3,
                    "parentId": this.addNodeForm.parentId,
                    "plotId": this.addNodeForm.plotId
                }
            }

            console.log('保存数据:', param)
            //防抖
            this.firstAction = true
            addNodeTree(param).then((res) => {
                if (res.message.status == 200) {
                    this.$message({
                        message: "保存成功",
                        type: "success",
                    });
                    this.addNodeForm.ammeterName = ''
                    this.addNodeForm.pileName = ''
                    this.isShowAmmeterModel = 1
                    this.addNodeForm.nodeType = 1
                    this.dialogVisibleLineAdd = false
                    this.getNodeTreeListFn(this.addNodeForm.lineId);
                    this.getLineAllListFn();
                }else{
                    //解除防抖
                    this.firstAction = false
                }
            })
        },
        handleMouseOver(e) {
            this.showDelImg = 1
            this.nodeIdDel = e.id
        },
        handleMouseLeave(e) {
            this.showDelImg = 2
            this.nodeIdDel = e.id
        },
        renderContent(h, { node, data, store }) {
            // powerCeilStr	功率上限, currentCeilStr 电流上限(单位:A)
            let showX = ''
            let showNodeName = ''
            let powerCeiStr = ''
            if (data.powerCeilStr === '0.00') {
                powerCeiStr = ''
            } else {
                powerCeiStr = '/' + data.powerCeilStr + 'kW'
            }
            if (data.refPhase != '') {
                showX = '/' + data.refPhase + '相'
            }
            if (data.nodeType == 2 || data.nodeType == 3) {
                showNodeName = data.nodeName + ' ' + '(' + data.nodeCode + ')'
            } else {
                showNodeName = data.nodeName
            }
            const imgStyle = {
                display: 'inline-flex'
            }
            //默认显示删除图标
            return (
                <span class="custom-tree-node">
                    <span>{showNodeName}</span>
                    <span>
                        {data.nodeType == 1 ? <span style="margin:0 4px 0 8px; padding: 0px 4px;border-radius: 2px;border: 1px solid #F87700;color:#F87700;">电站</span> : data.nodeType == 2 ? <span style="margin:0 4px 0 8px; padding: 0px 4px;border-radius: 2px;border: 1px solid #18ADD0;color:#18ADD0;">电表</span> : <span style="margin:0 4px 0 8px; padding: 0px 4px;border-radius: 2px;border: 1px solid #0DC8AC;color:#0DC8AC;">充电桩</span>}
                    </span>
                    <span style="padding: 2px 4px 0;border-radius: 2px;border: 1px solid #86909C;color: #86909C;font-size: 12px;">
                        {data.nodeType == 1 ? data.currentCeilStr + 'A' : data.nodeType == 2 ? data.currentCeilStr + 'A' + powerCeiStr : data.pileType == 0 ? '交流' + showX : '直流' + showX}
                    </span>
                    <span style={imgStyle}>
                        {data.nodeType == 1 ? '' : this.dialogVisibleLineAdd ? '' : <el-image style="margin-left:24px; width:16px; height: 16px" src={(node.id == this.nodeIdDel && this.showDelImg == 1) ? actionDel : delNodeIcon}
                            on-mouseover={() => this.handleMouseOver(node)}
                            on-mouseleave={() => this.handleMouseLeave(node)}
                            on-click={() => this.delLineNodeTreeFn(node, data)}
                        ></el-image>}
                    </span>
                </span>)
        },
        /**
        * 
        * 编辑节点所有方法
        * 
        */
        handleAmmeterChange(row, selectedValue){
            let getFilterData = this.editRightAmmeterForm.ammeterSelData.filter(item => item.id == selectedValue)
            this.$set(row, 'nodeCode', getFilterData[0].ammeterNo);
        },
        handlePileChange(row, selectedValue){
            let getFilterData = this.editRightPileForm.PileSelData.filter(item => item.id == selectedValue)
            console.log(getFilterData,456);
            this.$set(row, 'pileType', getFilterData[0].pileType==0?'交流':'直流');
            this.$set(row, 'connectorQty', getFilterData[0].portCount);
            this.$set(row, 'nodeCode', getFilterData[0].pileCode);
        },
        handleResetAmmeterEidt() { //编辑电表重置
            this.$refs.editRightAmmeterRef.resetFields()
        },
        handleResetPileEidt() { //编辑电桩重置
            this.$refs.editRightPileRef.resetFields()
        },
        handleElectricityMeterEdit() {
           
            this.$refs.editRightAmmeterRef.validate((valid) => {
                this.$refs.editRightAmmeterRef.clearValidate()
                if (this.currentItem.nodeType == 1) { //站点最高不支持编辑
                    this.$message('电站不支持编辑')
                    return
                }
                if (valid) {
                    const refId = this.editRightAmmeterForm.ammeterSelData.filter(item => item.id == this.editRightAmmeterForm.ammeterName)
                    let {
                        currentCeilStr,
                        nodeName,
                        nodeCode
                    } = this.editRightAmmeterForm.ammeterTableData[0];
                    let { id, lineId, nodeType, pileType } = this.currentItem;
                    let param = {
                        currentCeil: currentCeilStr,
                        id,
                        nodeName: refId[0].ammeterName,
                        plotId: this.addNodeForm.plotId,
                        lineId,
                        nodeType: nodeType,
                        parentId: this.editRightAmmeterForm.nodeName,
                        // refId: this.editRightAmmeterForm.ammeterName
                        refId: refId[0].id,
                        nodeCode
                    }
                    
                    console.log(this.editRightAmmeterForm.nodeName);
                    updateLineNodeTree(param).then((res) => {
                        if (res.message.status == 200) {
                            this.$message({
                                message: "保存成功",
                                type: "success",
                            });
                            // this.getNodeTreeListFn(this.addNodeForm.lineId);
                            this.nodeTreeDataList = []
                            this.getNodeTreeListFn(this.actionListLineId)
                        } else {
                            this.$message({
                                message: res.message.message,
                                type: "error",
                            });
                        }
                    });
                }
            });
        },
        handlePileEdit() {
            this.$refs.editRightPileRef.validate((valid) => {
                if (valid) {
                    const refId = this.editRightPileForm.PileSelData.filter(item => item.id == this.editRightPileForm.pileName)
                    console.log(refId,888);

                    let { nodeName, pileLimit, nodeCode } = this.editRightPileForm.pileTableData[0];
                    let { id, lineId, nodeType } = this.currentItem;
                    let param = {
                        id,
                        nodeName: refId[0].pileName,
                        plotId: this.addNodeForm.plotId,
                        lineId,
                        nodeType: nodeType,
                        parentId: this.editRightPileForm.nodeName,
                        refPhase: pileLimit, // 相线
                        refId: refId[0].id,
                        nodeCode,
                        pileType: refId[0].pileType
                    };
                    console.log(param, 888);
                    updateLineNodeTree(param).then((res) => {
                        if (res.message.status == 200) {
                            this.$message({
                                message: "保存成功",
                                type: "success",
                            });
                            // this.getNodeTreeListFn(this.addNodeForm.lineId);
                            this.nodeTreeDataList = []
                            this.getNodeTreeListFn(this.actionListLineId)
                        } else {
                            this.$message({
                                message: res.message.message,
                                type: "error",
                            });
                        }
                    });
                }
            });
        },
    },
};
</script>
<style scoped lang="scss">
.el-dropdown-link {
    cursor: pointer;
    margin-left: 10px;
    color: var(--color);
}

.el-icon-arrow-down {
    font-size: 12px;
}

.el-dropdown-menu__item {
    text-align: center;
}


// 弹框计算器隐藏
.NumberType ::v-deep input::-webkit-outer-spin-button,
.NumberType ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}

.NumberType ::v-deep input[type="number"] {
    -moz-appearance: textfield;
}

.threads-stop {
    .stop-remark {
        line-height: 22px;
    }
}

/* 使用深度选择器覆盖子组件样式 */
::v-deep .el-select-dropdown__item {
    padding: 0;
    background-color: #fff;
    /* 自定义背景颜色 */
}

::v-deep .el-dialog {
    border-radius: 8px;
}

::v-deep .el-dialog__body {
    padding: 14px 30px 22px 20px;
}

::v-deep .el-dialog__header {
    box-shadow: 0px 1px 0px 0px var(--PC--, #E5E6EB);
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #1D2129;
}

// 选中当前文字颜色修改
::v-deep .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content>.custom-tree-node {
    color: #057C98;
}

// 左边数三角形样式
::v-deep .el-tree .el-tree-node__expand-icon {
    color: #86909C;
}

/* 增加树形节点之间的垂直间距 */
::v-deep .el-tree .el-tree-node__content {
    margin-bottom: 4px;
    /* 根据需要调整这个值 */
}

::v-deep .el-form-item--small .el-form-item {
    margin-bottom: 0 !important;
}

//tree树形过长滚动条样式
.el-tree> :nth-child(n+1) {
    display: inline-block;
    min-width: 100%;
}

::v-deep .is-leaf::before {
    // 隐藏或加透明都可以
    // display: none;
    opacity: 0;
}
</style>